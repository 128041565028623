import { SET_IS_SHOW_PAGE_CONTENT_SPINNER, SET_PAGE_CONTENT, SET_PAGE_CONTENT_ID } from '../../Constants/ReduxKeys';

let initialState = {
  pageContent: null,
  pageContentId: null,
  isShowPageContentSpinner: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_CONTENT:
      return {
        ...state,
        pageContent: action.payload,
      };
    case SET_PAGE_CONTENT_ID:
      return {
        ...state,
        pageContentId: action.payload,
      };
    case SET_IS_SHOW_PAGE_CONTENT_SPINNER:
      return {
        ...state,
        isShowPageContentSpinner: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;