import React from 'react'
import ReactSelectOption from '../../../Components/SelectOption/ReactSelectOption';
import { fielsLengths } from '../../../Constants/MainKeys';

export default function BusinessType(props) {

  const { values, isInvalidSubmit, onChange, onSelectOptionChange, typeLibrary, structureLibrary } = props;

  return (
    <div className="row">
      <div className='col-12 mb-3'>
        <h3>Business Type</h3>
      </div>
      <div className="col-12">

        <div className='row'>
          {
            typeLibrary && typeLibrary.length ?
              <div className="col-lg-6 col-sm-12 mb-4">
                <label className='mb-1'>Type of Business</label>
                <ReactSelectOption
                  value={values.typeLibraryDataId}
                  selectedValue={(() => {
                    const selectedValue = { ...typeLibrary.find(data => data.id === values.typeLibraryDataId) };
                    if (selectedValue) {
                      selectedValue.label = selectedValue.displayName;
                      selectedValue.value = selectedValue.id;
                    }
                    return selectedValue;
                  })()}
                  items={typeLibrary.map(data => ({ label: data.displayName, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item.value, "typeLibraryDataId")}
                />
              </div>
              : null
          }
          {
            structureLibrary && structureLibrary.length ?
              <div className="col-lg-6 col-sm-12 mb-4">
                <label className='mb-1'>Business Structure</label>
                <ReactSelectOption
                  value={values.structureLibraryDataId}
                  selectedValue={(() => {
                    const selectedValue = { ...structureLibrary.find(data => data.id === values.structureLibraryDataId) };
                    if (selectedValue) {
                      selectedValue.label = selectedValue.displayName;
                      selectedValue.value = selectedValue.id;
                    }
                    return selectedValue;
                  })()}
                  items={structureLibrary.map(data => ({ label: data.displayName, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item.value, "structureLibraryDataId")}
                />
              </div>
              : null
          }
          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor='registerAddress' className='mb-1 cursor-pointer'>Register Address *</label>
            <input
              id="registerAddress"
              type="text"
              name="registerAddress"
              value={values.registerAddress}
              className={`${(isInvalidSubmit && !values.registerAddress.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "registerAddress", fielsLengths.length_120)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
