import React, { useState, useEffect } from 'react';
import ContactLaptopSvg from '../../assets/images/contact-laptop.svg'
import { fielsLengths } from '../../Constants/MainKeys';

export default function ContactUs() {

  var isInContactUsPage = window.location.pathname === "/contact-us";

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useEffect(() => {

    return () => {
      setIsInvalidSubmit(false);
    }
  }, [])

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!firstName.trim().length || !lastName.trim().length || !email.trim().length || !message.trim().length) {
      setIsInvalidSubmit(true);
      return;
    } else {
      const form = {
        firstName,
        lastName,
        email,
        message
      }
      console.log(form, "request part TODO");
    }
  }

  return <div className="row align-items-center">
    <div className="col-lg-6 mt-5">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="cx--form-control">
              <input
                type="text"
                name="firstName"
                placeholder='First name'
                value={firstName}
                className={`${(isInvalidSubmit && !firstName.trim().length) ? "cx--danger-border" : ""}`}
                onChange={event => onChange(event, setFirstName, fielsLengths.length_120)}
              />
              <span>First name</span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cx--form-control">
              <input
                type="text"
                name="lastName"
                placeholder='Last name'
                value={lastName}
                className={`${(isInvalidSubmit && !lastName.trim().length) ? "cx--danger-border" : ""}`}
                onChange={event => onChange(event, setLastName, fielsLengths.length_120)}
              />
              <span>Last name</span>
            </div>
          </div>
          <div className="col-12">
            <div className="cx--form-control">
              <input
                type="email"
                name="email"
                placeholder='E-mail'
                value={email}
                className={`${(isInvalidSubmit && !email.trim().length) ? "cx--danger-border" : ""}`}
                onChange={event => onChange(event, setEmail, fielsLengths.length_120)}
              />
              <span>E-mail</span>
            </div>
          </div>
          <div className="col-12">
            <div className="cx--form-control">
              <textarea
                placeholder="Hi Dear Relamy team..."
                name="message"
                rows="4"
                style={{resize:"none"}}
                value={message}
                className={`${(isInvalidSubmit && !message.trim().length) ? "cx--danger-border" : ""}`}
                onChange={event => onChange(event, setMessage, fielsLengths.length_2000)}
              />
              <span>Type message...</span>
            </div>
          </div>
          <div className="col-12">
            <button className="cx--btn cx--btn-primary" type="submit">send</button>
          </div>
        </div>
      </form>
    </div>
    <div className="col-lg-6 text-center d-lg-block d-none">
      <img className="cx--aside-image" src={ContactLaptopSvg} alt="contact-us" />
    </div>
  </div>
}
