export const TOKEN_KEY = 'token';
export const LANGUAGE_KEY = 'language';
export const LANGUAGES_KEY = 'languages';
export const DEFAULT_LANGUAGE_KEY = 'en';
export const USER_KEY = 'user';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const TRANSLATE_KEYS = 'translatekeys';
export const OBJECT_KEY = 'object';
export const INFO_KEY = "info";
export const ERROR_KEY = 'error';
export const SUCCESS_KEY = "success";
export const WARNING_KEY = "warning";
export const INVALID_USERNAME_OR_PASSWORD = "invalid_username_or_password";
export const NUMBER_KEY = "number";
export const SPINNER_COLOR = "#1081E8";



export const fielsLengths = {
  length_4: 4,
  length_6: 6,
  length_25: 25,
  length_30: 30,
  length_32: 32,
  length_100: 100,
  length_120: 120,
  length_250: 250,
  length_2000: 2000,
  length_9999: 9999,
  length_1000000000: 1000000000,
}