import React from 'react'

export default function SecureYourAccount(props) {

  const { values, onCheckboxChange } = props;

  return (
    <div className="row">
      <div className='col-12 mb-3'>
        <h3>Secure Your Account</h3>
      </div>
      <div className="col-12">
        <div className='row'>
          <div className="d-flex align-items-baseline">
            <input
              id='useSMS'
              type="checkbox"
              name="useSMS"
              className='mx-2 d-block'
              checked={values.useSMS}
              onChange={event => onCheckboxChange(event, "useSMS")}
            />
            <label htmlFor='useSMS' className='mb-1 cursor-pointer'>Use SMS</label>
          </div>

          <div className="my-4">
            <button className='cx--btn cx--btn-primary-outline'>
              Authenticator App
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
