import React from 'react';
import { useSelector } from 'react-redux';
import ButtonSpinner from '../Spinners/ButtonSpinner';

const ActionButton = (props) => {
  const buttonSpinners = useSelector(state => state.spinner.buttonSpinners);
  const currentSpinner = buttonSpinners.find(spinner => spinner === props.spinnerId);
  return <button
    type={props.type}
    color={props.color}
    disabled={currentSpinner || props.disabled}
    className={`d-flex justify-content-center align-items-center ${props.className}`}
    onClick={props.onClick}>
    {
      currentSpinner ?
        <React.Fragment>
          {'\u00A0'}
          <ButtonSpinner spinner={currentSpinner} spinnerColor={props.spinnerColor ? props.spinnerColor : "#fff"} />
          {'\u00A0'}
        </React.Fragment> :
        props.name ? props.name : props.children
    }
  </button>
};

export default ActionButton;  