import React from 'react'
import ReactSelectOption from '../../../Components/SelectOption/ReactSelectOption';
import { fielsLengths } from '../../../Constants/MainKeys';

export default function BusinessDetails(props) {

  const { values, isInvalidSubmit, onChange, onNumberChange, onSelectOptionChange, industryLibrary } = props;

  return (
    <div className="row">
      <div className='col-12 mb-3'>
        <h3>Business Details</h3>
      </div>
      <div className="col-12">
        <div className='row'>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="organizationName" className='mb-1 cursor-pointer'>Legal Business name *</label>
            <input
              id="organizationName"
              type="text"
              name="organizationName"
              value={values.organizationName}
              className={`${(isInvalidSubmit && !props.organizationName.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "organizationName", fielsLengths.length_120)}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="employerNumber" className='mb-1 cursor-pointer'>Employer Identification Number *</label>
            <input
              id="employerNumber"
              type="text"
              name="employerNumber"
              value={values.employerNumber}
              onChange={event => onNumberChange(event, "employerNumber")}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="DBAName" className='mb-1 cursor-pointer'>DBA name (Optional) *</label>
            <input
              id="DBAName"
              type="text"
              name="DBAName"
              value={values.DBAName}
              className={`${(isInvalidSubmit && !values.DBAName.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "DBAName", fielsLengths.length_120)}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="websiteUrl" className='mb-1 cursor-pointer'>Business website *</label>
            <input
              id="websiteUrl"
              type="text"
              name="websiteUrl"
              value={values.websiteUrl}
              className={`${(isInvalidSubmit && !values.websiteUrl.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "websiteUrl", fielsLengths.length_120)}
            />
          </div>
          {
            industryLibrary && industryLibrary.length ?
              <div className="col-lg-6 col-sm-12 mb-4">
                <label className='mb-1'>Industry</label>
                <ReactSelectOption
                  value={values.industryLibraryDataId}
                  selectedValue={(() => {
                    const selectedValue = { ...industryLibrary.find(data => data.id === values.industryLibraryDataId) };
                    if (selectedValue) {
                      selectedValue.label = selectedValue.displayName;
                      selectedValue.value = selectedValue.id;
                    }
                    return selectedValue;
                  })()}
                  items={industryLibrary.map(data => ({ label: data.displayName, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item.value, "industryLibraryDataId")}
                />
              </div>
              : null
          }

          <div className="col-12">
            <label htmlFor='description' className='cursor-pointer'>Product Description</label>
            <textarea
              id='description'
              placeholder="Product Description..."
              name="description"
              rows="4"
              style={{ resize: "none" }}
              value={values.description}
              className={`${(isInvalidSubmit && !values.description.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "description", fielsLengths.length_2000)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
