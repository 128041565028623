import React, { useState, useEffect } from 'react';
import ContactUsComponent from '../ContactUs/ContactUsComponent';

export default function ContactUs() {

  var isInContactUsPage = window.location.pathname === "/contact-us";

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useEffect(() => {

    return () => {
      setIsInvalidSubmit(false);
    }
  }, [])

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!firstName.trim().length || !lastName.trim().length || !email.trim().length || !message.trim().length) {
      setIsInvalidSubmit(true);
      return;
    } else {
      const form = {
        firstName,
        lastName,
        email,
        message
      }
      console.log(form, "request part TODO");
    }
  }

  return <section className="cx--section mt-5">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="cx--section-title cx--section-dark-title">
            <span className="cx--primary-color">Need</span> help? <br />
            Don't hesitate to <span className="cx--primary-color">reach out</span>.
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-5">
          <div className="cx--contact-info-wrapper">
            <strong className="cx--section-title-sm">Customer support</strong>
            <p className="cx--contact-info-text mt-3">We have the answers to your questions. And if we don't, we'll get them for you.</p>
            <br />
            <p className="cx--contact-info-text">
              1. Check out our running list of help forums. <br />
              2. Use our direct chat for answers tailored specifically to your unique situation.
            </p>
          </div>
        </div>
        <div className="col-md-6 mt-5">
          <div className="cx--contact-info-wrapper">
            <strong className="cx--section-title-sm">Technical Support</strong>
            <p className="cx--contact-info-text mt-3">
              Reach out to our tech team for answers to your technical questions.
              Access our tech tutorials for some of the most common problems you may face or submit a
              request for your specific problem and we will get back to you as soon as possible.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <ContactUsComponent />
    </div>
  </section>
}
