import React from 'react'
import ReactSelectOption from '../../../Components/SelectOption/ReactSelectOption';

export default function FulfillmentDetails(props) {

  const { values, onSelectOptionChange, customersTypeLibrary, sellTypeLibrary } = props;

  return (
    <div className="row">
      <div className='col-12 mb-3'>
        <h3>Fulfillment Details</h3>
      </div>
      <div className="col-12">

        <div className='row'>
          {
            customersTypeLibrary && customersTypeLibrary.length ?
              <div className="col-lg-6 col-sm-12 mb-4">
                <label className='mb-1'>Your customers are</label>
                <ReactSelectOption
                  value={values.customersTypeLibraryDataId}
                  selectedValue={(() => {
                    const selectedValue = { ...customersTypeLibrary.find(data => data.id === values.customersTypeLibraryDataId) };
                    if (selectedValue) {
                      selectedValue.label = selectedValue.displayName;
                      selectedValue.value = selectedValue.id;
                    }
                    return selectedValue;
                  })()}
                  items={customersTypeLibrary.map(data => ({ label: data.displayName, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item.value, "customersTypeLibraryDataId")}
                />
              </div>
              : null
          }
          {
            sellTypeLibrary && sellTypeLibrary.length ?
              <div className="col-lg-6 col-sm-12 mb-4">
                <label className='mb-1'>You sell</label>
                <ReactSelectOption
                  value={values.sellTypeLibraryDataId}
                  selectedValue={(() => {
                    const selectedValue = { ...sellTypeLibrary.find(data => data.id === values.sellTypeLibraryDataId) };
                    if (selectedValue) {
                      selectedValue.label = selectedValue.displayName;
                      selectedValue.value = selectedValue.id;
                    }
                    return selectedValue;
                  })()}
                  items={sellTypeLibrary.map(data => ({ label: data.displayName, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item.value, "sellTypeLibraryDataId")}
                />
              </div>
              : null
          }
        </div>
      </div>
    </div>
  )
}
