import React, { useCallback, useState, useEffect } from 'react'
import signInSvg from "./../../assets/images/sign-in.svg";
import signInDecorSvg from "./../../assets/images/decors/sign-in-decor.svg";
import { Link } from 'react-router-dom';
import ActionButton from '../../Components/Buttons/ActionButton';
import uuid from 'react-uuid';
import AlertService from '../../Services/AlertService';
import { addButtonSpinner, removeButtonSpinner } from '../../Store/Actions/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin, removeLoginErrorMessage } from '../../Store/Actions/user';
import { INVALID_USERNAME_OR_PASSWORD, fielsLengths } from '../../Constants/MainKeys';

const buttonSpinnerId = uuid();
export default function SignIn() {

  const dispatch = useDispatch()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const { loginErrorMessage } = useSelector(state => state.user);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    dispatch(removeLoginErrorMessage());
  }

  const onSubmit = (event) => {
    event && event.preventDefault();

    if (!username.trim().length || !password.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {

      const form = {
        username: username.trim(),
        password: password.trim()
      }
      dispatch(userLogin(form, buttonSpinnerId))
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return <section className="cx--cover-container">
    <img className="cx--cover-decor" src={signInDecorSvg} alt="signin-decor"></img>
    <div className="container">
      <div className="cx--cover-body-wrapper">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="cx--cover-form">
              <div className="mb-5 d-block">
                <h1 className="cx--cover-form-title">
                  sign in.
                </h1>
                <p className="cx--cover-sub-title">Not registered yet? <Link to="/sign-up"> Create an account</Link></p>
              </div>
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="cx--form-control">
                      <input
                        name="username"
                        type="email"
                        placeholder="example@relamy.com"
                        autoComplete="new-password"
                        value={username}
                        className={`${(isInvalidSubmit && !username.trim().length) ? "cx--danger-border" : ""}`}
                        onChange={event => onChange(event, setUsername, fielsLengths.length_120)}
                      />
                      <span>E-mail</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="cx--form-control mb-4">
                      <input
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        value={password}
                        className={`${(isInvalidSubmit && !password.trim().length) ? "cx--danger-border" : ""}`}
                        onChange={event => { onChange(event, setPassword, fielsLengths.length_120) }}
                      />
                      <span>Password</span>
                    </div>
                  </div>
                  {
                    loginErrorMessage === INVALID_USERNAME_OR_PASSWORD ?
                      <small className='text-danger d-block mb-4'>Invalid username or password</small>
                      : null
                  }
                  <div className="col-12">
                    <ActionButton
                      name="sign in"
                      type="submit"
                      className="cx--btn cx--btn-primary"
                      spinnerId={buttonSpinnerId}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cx--cover-image">
              <img src={signInSvg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
