import $ from 'jquery';

class MainService {

  static triggerUploadClick(event) {
    let inputElem = $($(event.target).find('input:file'))[0];
    if (!inputElem) {
      inputElem = $($(event.target.parentElement).find('input:file'))[0];
    }
    $(inputElem).trigger('click');
  }

  readFile(file, validFileTypes = null) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      const isValid = validFileTypes.find(data => data === fileExtention);
      !isValid && reject(true);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      }
    });
  }

  static isValidField(fieldValue, fieldName) {
    if (fieldName === "firstName" || fieldName === "lastName" || fieldName === "firstname" || fieldName === "lastname" || fieldName === "name") {
      const idValid = /^([a-zA-Z]{1,15})([\s{0,1}]?)([-{0,1}]?)([a-zA-Z]{1,15})$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "email" || fieldName === "organizationEmail") {
      // const idValid = /^[A-z,0-9,]{1,30}\d*@[A-z,0-9,]*.[A-z]*$/;
      const idValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "password" || fieldName === "confirmPassword" || fieldName === "newPassword") {
      // const idValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;
      const idValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]([^\s@]+){6,}$/;
      return idValid.test(fieldValue);
    }
  }

  static getUrlExtension() {
    return window.location.hostname.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
  }


}

export default MainService;