import React from "react";
import { GridLoader } from "react-spinners";

function PageSpinner(props) {

  let color = "#0533B3";

  return (
    props.spinner && props.spinner.length ?
      <div className="spinner-layer">
        <div className="sweet-loading">
          <GridLoader
            size={15}
            color={color} />
        </div>
      </div>
      : null
  );
}

export default PageSpinner;