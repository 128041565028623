import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

export default function PrivacyPolicy() {

  const navigate = useNavigate();

  const scrollToCurrentPosition = (activeSection) => {
    const currentSection = document.getElementById(activeSection);
    currentSection && window.scrollTo({
      top: currentSection?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
      behavior: "smooth"
    });
  }


  return (
    <section className="cx--section mt-5">
      <div className="container">
        <div className='row'>
          <div className="cx--contact-info-wrapper">
            <strong className="cx--section-title-sm">Privacy Policy</strong>
            <small className="d-block cx--contact-info-text mt-3"><span className='cursor-pointer text-underline' onClick={() => navigate("/")}>Home</span> / Privacy Policy</small>
            <small className="d-block cx--contact-info-text">Cryllex Inc.</small>
            <small className="d-block cx--contact-info-text">Last Updated: December 23rd, 2022 (example)</small>
          </div>

          <div className="cx--contact-info-wrapper mt-5">
            <strong className="cx--section-title-lg">TABLE OF CONTENTS</strong>
            <ol className='mt-2'>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("commitmentToPrivacy")}><Link to="#">COMMITMENT TO PRIVACY</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("overview")}><Link to="#">OVERVIEW</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("theInformationWeCollect")}><Link to="#">THE INFORMATION WE COLLECT</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("howWeUseCookies")}><Link to="#">HOW WE USE COOKIES</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("howWeUseThePersonalInfo")}><Link to="#">HOW WE USE THE PERSONAL INFORMATION WE COLLECT</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("howWeProtectAndStorePersonalInfo")}><Link to="#">HOW WE PROTECT AND STORE PERSONAL INFORMATION</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("howWeShareDataWithThirdParties")}><Link to="#">HOW WE SHARE DATA WITH THIRD-PARTIES</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("contactInfo")}><Link to="#">CONTACT INFORMATION</Link></li>
            </ol>
          </div>

          <div id="commitmentToPrivacy" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">COMMITMENT TO PRIVACY</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                Cryllex Global, Inc. understands that you care about how your personal information is used and
                disclosed and we take your privacy very seriously. Although our primary purpose is to provide you
                with an excellent product and exceptional service, we are committed to protecting your privacy and
                ensuring that information regarding your personal information is readily accessible. To make this
                privacy policy easy to find, we make it available from the home page of the Cryllex website at
                cryllex.com (the “Sites”). You may also refer to the contact us section of our website with any
                privacy-related questions you have.
              </span>
            </div>
          </div>

          <div id="overview" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">OVERVIEW</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                The Privacy Statement (“Statement”, “Notice”, “Policy”) describes the ways Cryllex Global, Inc.
                (“Cryllex”, “we”, “us”, “our”) collects, stores, uses and protects information. This statement applies to all
                information we collect through our services including merchant processing services, websites, mobile
                applications, products and any other features, technologies or functionalities, such as customer
                support (“Services”) offered by us. This Notice applies to cryllex.com and all other Cryllex Global sites
                on which it appears (the “Sites”).
              </span>
              <span className='d-block my-3'>
                Our privacy practices may vary among the countries or territories where we operate to reflect local
                practices and legal requirements. As the Services evolve, we may occasionally update this privacy
                statement to reflect changes to our business, Services, or applicable laws. If the revised version
                requires notice in accordance with applicable law, we will provide you with 30 days prior notice by
                posting notice of the change on the “Privacy Policy” page of our website. Otherwise, the revised
                Privacy Statement will be effective as of the published effective date, and you are responsible for
                periodically reviewing this Notice.
              </span>
              <span className='d-block mt-3'>We may process information from the following types of users:</span>
              <ol className='mt-3'>
                <li>
                  Visitors of our Sites;
                </li>
                <li>
                  Merchants who sign up for our Services (“Merchants” meaning any business that uses our
                  Services to process payments, including non-profits that use our Services to accept donation
                  and “Aggregators” that use our Services as payment services platform for their own
                  Merchants);
                </li>
                <li>
                  Shoppers of Merchants (“Shoppers” meaning individuals who indirectly interface with Cryllex
                  Global when paying a Merchant's invoice that is forwarded by Cryllex Global to a Merchant
                  during checkout, when requesting a refund from a Merchant, when creating a Cryllex Global
                  account, or when making a donation to a non-profit)
                </li>
                <li>
                  Payees (e.g. employees, contractors, vendors or exchange customers) who request a payment
                  (“Payout”) via cryptocurrency from a Merchant and create a Cryllex Global account.
                </li>
              </ol>
              <span>
                By using the Site or Service, you agree to the collection and use of information in accordance with this
                privacy statement and the applicable user agreement.
              </span>
            </div>
          </div>

          <div id="theInformationWeCollect" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">THE INFORMATION WE COLLECT</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                We collect different kinds of information when users interact with us. The information we collect
                depends on the type of user. We collect information you choose to provide to us when interacting
                with our Services. We may also collect information automatically from your devices. The following
                sections will provide additional information.
              </span>
              <ol>
                <li>
                  <p className='mt-4 mb-2 text-underline'>
                    Visitors of Our Sites
                  </p>
                  <span className='d-block'>
                    When you visit the website or use one of our services, we collect your IP address and standard
                    web log information, such as your browser type and the pages you accessed on our website.
                    We may also collect information about a visitor's computer or other access devices for
                    purposes including fraud prevention. The information we collect may be collected
                    automatically using cookies or other similar technologies. For more information about our use
                    of cookies, please refer to our Cookie Policy. If you do not agree to our collection of this
                    information, you may not be able to use our Service.
                  </span>
                  <ul className='mt-3' >

                    <li className='list-style-disc'>
                      First and last name (e.g. when you submit a support request)
                    </li>

                    <li className='list-style-disc'>
                      Email address (e.g. when you subscribe to our blog or opt in to receive other
                      marketing materials)
                    </li>

                    <li className='list-style-disc'>
                      Phone number (e.g. when you submit a contact request or opt in to our media list)
                    </li>

                    <li className='list-style-disc'>
                      Country
                    </li>

                    <li className='list-style-disc'>
                      Technical information, including IP address; the type of browser, devices and
                      operating systems you use; identifiers associated with the device(s) you use to access
                      our Sites; the pages you visit and the features you use; access dates and times; ad if you navigated from or navigate to another website, the address of that website; and
                      information regarding your internet service provider
                    </li>

                    <li className='list-style-disc'>
                      Other information collected from cookies or other tracking technologies
                    </li>

                  </ul>
                </li>

                <li>
                  <p className='mt-4 mb-2 text-underline'>
                    Merchants (including businesses such as nonprofits and Aggregators)
                  </p>
                  <span className='d-block'>
                    During the onboarding process, we may collect the following information of the beneficial
                    owner or any user that is added to an account of a Merchant (including nonprofits and
                    Aggregators that use our Services as a payment services platform for their own Merchants).
                    For Aggregators, we may collect the information of that Aggregator's Merchants.
                  </span>
                  <span className='d-block mt-3'>
                    The element we collect depends on our compliance analysis and the client risk profile; some
                    include:
                  </span>
                  <ul className='mt-3' >

                    <li className='list-style-disc'>
                      Personal information of beneficial owner(s) and authorized user(s), including full
                      name, personal email address, and phone number;
                    </li>

                    <li className='list-style-disc'>
                      Government identification of beneficial owner(s) and authorized user(s), such as a
                      government issued passport or driver's license, as well as Tax identification number (if
                      applicable according to country laws and regulations);
                    </li>

                    <li className='list-style-disc'>
                      Corporate information, such as merchant email address (business domain preferred),
                      full physical address (no post office boxes allowed), and business telephone number;
                    </li>

                    <li className='list-style-disc'>
                      Company bank account details;
                    </li>

                    <li className='list-style-disc'>
                      Three most recent bank statements or bank letter of good standing, proof of business,
                      and proof of address (if applicable)
                    </li>

                  </ul>
                  <span className='d-block mt-3'>
                    We may use this information for risk management purposes (i.e. to verify Merchant’s identity
                    or address) or to comply with laws and regulation, including those applicable to financial
                    services institutions. We may also obtain information about our Merchants from third parties
                    that provide identity verification and fraud prevention services and from relevant Aggregators.
                  </span>
                </li>

                <li>
                  <p className='mt-4 mb-2 text-underline'>
                    Shoppers of Merchants
                  </p>
                  <span className='d-block'>
                    We may collect information when a Shopper indirectly interfaces with our Services to pay a
                    Merchant's invoice, request a refund and/or make donations, or creates a consumer account
                    through our Website:
                  </span>
                  <ul className='mt-3' >
                    <li className='list-style-disc'>
                      When you pay a Cryllex Global invoice, we collect:
                      <ul>
                        <li className='list-style-circle'>
                          Identification information, such as your first and last name, email address, and
                          cryptocurrency wallet address
                        </li>

                        <li className='list-style-circle'>
                          Age or date of birth and citizenship through Government-issued identification
                          (if applicable based on local laws and legislations)
                        </li>

                        <li className='list-style-circle'>
                          Information that identifies with reasonable specificity your location by using,
                          for instance, longitude and latitude coordinates obtained through GPS, Wi-Fi,
                          or cell site triangulation.
                        </li>

                        <li className='list-style-circle'>
                          Technical information including IP addresses used to view the invoice; the
                          type of browser, devices and operating systems you use; identifiers associated
                          with the device(s) you use to access our sites; the pages you visit and the
                          features you use; access dates and times; and if you navigated from or
                          navigate to another website, the address of that website; and information
                          regarding your internet service provider
                        </li>

                        <li className='list-style-circle'>
                          Other information collected from cookies or other tracking technologies
                        </li>

                        <li className='list-style-circle'>
                          Order and product information at the relevant merchant
                        </li>

                      </ul>
                    </li>

                    <li className='list-style-disc'>
                      When you request a refund, we collect:
                      <ul>

                        <li className='list-style-circle'>
                          Information related to the relevant transaction, such as the amount, currency,
                          and invoice ID
                        </li>

                        <li className='list-style-circle'>
                          Personal information, such as your email address and crypto wallet address.
                        </li>

                      </ul>
                    </li>

                    <li className='list-style-disc'>
                      Certain Shoppers may choose to or may be required to create a Cryllex account; in
                      doing so, we may collect additional information from you.
                    </li>

                  </ul>
                  <span className='d-block mt-4'>
                    We may collect this information either from the Merchant or directly from the Shopper.
                    Collecting this information supports our ability to provide our Services. This enables our
                    system to send an email to Shoppers directly to obtain a cryptocurrency refund address in
                    case of a payment exception (e.g. overpayments, underpayments, etc.). This also creates a
                    more seamless payment experience for both the Merchant and the Shopper.
                  </span>
                  <span className='d-block my-3'>
                    Additionally, upon the Merchant's explicit request, we may collect and store information for
                    the Merchant's benefit only and as a service through our invoicing service. Information that
                    Merchants may request about Shoppers may include:
                  </span>
                  <ul className='mt-3' >

                    <li className='list-style-disc'>
                      Personal information such as first and last name, address, email address, and crypto
                      wallet address
                    </li>

                    <li className='list-style-disc'>
                      Transaction information such as the transaction identification number and details.
                    </li>

                  </ul>
                </li>

                <li>
                  <p className='mt-4 mb-2 text-underline'>
                    Payees of Merchants
                  </p>
                  <span className='d-block'>
                    Cryllex Global offers a service for Merchants to pay individuals (employees, contractors,
                    vendors and exchange customers) via cryptocurrency (“Payees”).
                  </span>
                  <span className='d-block my-3'>
                    The following information may be collected from Payees in certain instances:
                  </span>
                  <ul className='mt-3' >
                    <li className='list-style-disc'>Personal information, such as first and last name, email address, and cryptocurrency wallet address/provider</li>
                    <li className='list-style-disc'>Identification documents, such as a government issued passport or driver's license</li>
                    <li className='list-style-disc'>Official documentation showing proof of address, such as a bank statement or a utility bill.</li>
                  </ul>
                </li>

                <li>
                  <p className='mt-4 mb-2 text-underline'>
                    Additional Collection
                  </p>
                  <span className='d-block'>
                    Cryllex Global may collect additional information in connection with its efforts to combat
                    fraud, illegal activity, or comply with laws and regulations. Additionally, Cryllex Global may
                    collect information regarding the effectiveness of our email and other communications. For
                    example, we may know if you follow a link in an email we send to you. We may also obtain
                    information about you from other third parties. This may include information about your use
                    of this Site or our Services. We may also collect information about former, existing and
                    prospective vendors and customers (“Partners”). This includes limited personal information
                    about employees and agents of such Partners (“Partner Employees”) at the beginning and
                    throughout the course of the Partner relationships, such as professional contact information.
                  </span>
                </li>
              </ol>
            </div>
          </div>

          <div id="howWeUseCookies" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">HOW WE USE COOKIES</strong>
            <p className="cx--contact-info-text mt-2">
              <span>
                When you visit or use our Services,or visit a third-party website for which we provide online services,
                we and certain business partners and vendors may use cookies, clear GIFS or web beacons, or other
                identifiers or tracking technologies to collect information aboutWe may collect information about
                your activities on our sites. We refer to these technologies collectively as “cookies”.
              </span>
              <span className='d-block my-3'>
                We may allow third parties to use cookies on our sites. The information collected by cookies may be
                shared with and used by us, by others acting on our behalf, or by third pirates subject to their own
                privacy policies. Information collected by cookies may be used on this site or on other websites or
                services, including those that may not be operated by us. Other parties may collect personally
                identifiable information about an individual consumer's online activities over time and across different
                websites when a consumer uses our websites or online services. For more information about our use
                of cookies, please refer to our Cookie Policy.
              </span>
              <span className='d-block my-3'>
                You can control the use of cookies at the individual browser level. If you reject cookies, you may still
                use our website, but your ability to use some features or areas of our website may be limited. You may
                control how your browser accepts cookies. For more information, please consult your browser's help
                documentation. You may also manage your cookie preferences using our Cookie Preference Center.
                For more information about our use of cookies, please refer to our Cookie Policy.
              </span>
              <span className='d-block my-3'>
                Certain Services are only available through the use of cookies, so if you choose to disable or decline
                cookies, your use of certain Services may be limited or not possible.
              </span>
              <span className='d-block my-3'>
                As of the last updated date of this Notice, there is no commonly accepted response for Do Not Track
                signals initiated by browsers. Therefore, we do not respond to such signals or to other mechanisms
                that provide the ability to exercise choice regarding the collection of personally identifiable
                information regarding your online activities over time and across third-party websites or online
                services.
              </span>
              <span className='d-block my-3'>
                If you do not wish to receive marketing communications from us, you can click the “unsubscribe” link
                usually found at the bottom of the email, or you can send an email to info@cryllex.com. However, you
                may still receive other communications from us as permitted or required by law.
              </span>
            </p>
          </div>

          <div id="howWeUseThePersonalInfo" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">HOW WE USE THE PERSONAL INFORMATION WE COLLECT</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                We primarily use the information we collect to provide you with a safe, smooth, efficient, and
                customized experience. Specifically, we may use your personal information to:
              </span>
              <ul className='mt-3'>
                <li>
                  Run our sites and better provide the services and customer support you request, for example
                  to help you make purchases, show you your account information, verify access to your
                  account, and keep your account information up to date.
                </li>
                <li>
                  Process transactions and send notices about your transactions or network activity;
                </li>
                <li>
                  Resolve disputes, collect fees, and troubleshoot problems;
                </li>
                <li>
                  Protect our business and our customers by using it to prevent potentially fraudulent,
                  prohibited or illegal activities, and enforce our User Agreement through the use of our risk and
                  fraud tools which may include use of the information you provide;
                </li>
                <li>
                  Communicate with you about our Services and business and to inform you of matters that are
                  important for your account and/or use of the Sites
                </li>
                <li>
                  Respond to any questions, comments or requests you filed with us and the handling of any
                  complaints;
                </li>
                <li>
                  Establish, exercise and defend legal claims;
                </li>
                <li>
                  Monitor and report compliance issues;
                </li>
                <li>
                  Customize, measure and improve our business , the Services, and the content and layout of
                  our website and applications (including developing new products and services; managing our
                  communications; determining the effectiveness of our sales, marketing and advertising;
                  analyzing and enhancing our products, services, websites and apps; ensuring the security of
                  our networks and information systems; performing accounting, auditing, invoicing,
                  reconciliation and collection activities; and improving and maintaining the quality of our
                  customer services);
                </li>
                <li>
                  Perform data analysis;
                </li>
                <li>
                  Deliver targeted marketing, service update notices, and promotional offers based on your
                  communication preferences. To approach you via email for marketing purposes, we request
                  your consent, unless it is not required by law. You always have the option to unsubscribe from
                  our mailings (e.g. via the unsubscribe link in our newsletter);
                </li>
                <li>
                  Perform risk management, including comparing information for accuracy and verify it with
                  third parties and protect against, identify and prevent fraud and other prohibited or illegal
                  activity, claims and other liabilities; and
                </li>
                <li>
                  Enforce our contractual terms.
                </li>
                <li>
                  Perform other duties as required by applicable laws and regulations.
                </li>
              </ul>
            </div>
          </div>

          <div id="howWeProtectAndStorePersonalInfo" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">HOW WE PROTECT AND STORE PERSONAL INFORMATION</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                We take the security of our information very seriously. We use administrative, technical and physical
                security designed to safeguard personal information in our possession, and we maintain internal
                policies and procedures to address our data security. We store and process your personal information
                using third party servers located in data centers in ____. This information is protected by physical,
                electronic and procedural safeguards in compliance with the applicable laws and legislations. We also
                use computer safeguards such as firewalls and data encryption and authorize access to personal
                information only for those employees who require it to fulfill their job responsibilities.
              </span>
              <span className='d-block my-3'>
                We strive to ensure the highest level of security, confidentiality, and availability. However, we cannot
                guarantee that personal information may not be accessed, disclosed, altered, or destroyed by breach
                of our administrative, managerial, and technical safeguards. Therefore, we urge you to take adequate
                precautions to protect your personal information as well, including never sharing your Cryllex
                password with anyone.
              </span>
              <span className='d-block my-3'>
                We will notify users electronically in the event of a data breach so that you can take appropriate
                protective steps. By using the Services, you agree that Cryllex may communicate with you
                electronically. We will also notify the relevant authorities in the event of a data breach if required
                under applicable law. For example, as a regulated financial institution, we may retain identification for
                5 years or more after an account has been closed or become dormant, unless we need to keep it
                longer, such as:
              </span>
              <ul className='mt-3'>
                <li>
                  A legal obligation or compliance to laws which we are subject is retained consistent with the
                  applicable law, such as under Anti-Money Laundering obligations
                </li>
                <li>
                  Litigation, investigations, audit, and compliance practices, or to protect against legal claims.
                </li>
              </ul>
            </div>
          </div>

          <div id="howWeShareDataWithThirdParties" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">HOW WE SHARE DATA WITH THIRD-PARTIES</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                Cryllex does not disclose your personal information with third parties for their promotional or
                marketing purposes.
              </span>
              <span className='d-block my-3'>
                We may disclose the information we gather with our affiliates and third parties. For example, we may
                share information with:
              </span>
              <ul className='mt-3'>
                <li>
                  Other Cryllex Global entities, including to help detect and prevent potentially illegal acts and
                  violations of our policies, and to guide decisions about our products, services and
                  communications;
                </li>
                <li>
                  Service providers under contract who help with our business operations and to deliver our
                  Services, such as cloud service providers providing cloud infrastructure; providers of ID
                  verification solutions and other due diligence solutions; providers of customer service
                  solutions; providers of website analytics; and providers of marketing automation platforms;
                </li>
                <li>
                  Law enforcement, government officials, regulatory agencies, our banks, and other third parties
                  pursuant to a subpoena, court order or other legal process or requirement if applicable to
                  Cryllex Global; or when we believe, in our sole discretion, that the disclosure of information is
                  necessary to report suspected illegal or fraudulent activity or to investigate violations of our
                  Terms of Use; and
                </li>
                <li>
                  We may also share certain Shopper information with our Merchants, for example, in cases of
                  suspected fraud or in connection with an ongoing investigation.
                </li>
              </ul>
              <span className='d-block mt-3'>
                Please note that these third parties may be in other countries where the laws on processing
                information may be less stringent than in your country.
              </span>
            </div>
          </div>

          <div id="contactInfo" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">CONTACT INFORMATION</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                You may contact us with questions or concerns about our privacy policies or practices at
                info@cryllex.com. If you wish to submit a data privacy rights request, you may do so by emailing us at
              </span>
              &nbsp;
              <Link to='#' className='text-nowrap'>info@cryllex.com</Link>.
            </div>
          </div>

        </div>
      </div>
    </section >
  )
}
