import React from 'react'
import { useSelector } from 'react-redux';
import Footer from '../LayoutSections/Footer/Footer';
import Header from '../LayoutSections/Header/Header';
import AuthUserRouting from './../../Routing/AuthUserRouting/AuthUserRouting';

export default function AuthUsersLayout() {

  const { user } = useSelector(state => state.user);

  return (
    <React.Fragment>
      <Header />
      <AuthUserRouting />
      <Footer />
    </React.Fragment>
  )
}
