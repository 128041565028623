import React from "react";
import { GridLoader } from "react-spinners";

function FormSpinner(props) {

  let color = "#0533B3";

  return (
    <div className={`spinner-layer ${props.blockClassName}`}>
      <div className="sweet-loading">
        <GridLoader
          size={10}
          color={color} />
      </div>
    </div>
  );
}

export default FormSpinner;