import { confirmAlert } from 'react-confirm-alert';
import errorSvg from "../assets/icons/remove.svg";
import successSvg from "../assets/icons/check.svg";
import closeSvg from "../assets/icons/close-white.svg";
import warningSvg from "../assets/icons/warning.svg";
import infoSvg from "../assets/icons/info.svg";

export default class AlertService {

  static checkMessageType = (respcode) => {
    switch (respcode) {
      case 0:
        return "success"
      case 1:
        return "error"
      case 2:
        return "warning"
      case 3:
        return "info"
      default:
        break;
    }
  };

  static alert = (type, error) => {
    const respMessage = typeof error === "object" ? error.message || error.respmess : error;
    // if (!respMessage || (respMessage && respMessage.includes("TR_"))) { return; }
    if (!respMessage || (respMessage && respMessage.includes("401"))) { return false; }

    var alertBlockWithCloseButton = document.createElement("div");
    alertBlockWithCloseButton.className = "cx--alert-block";
    var containerElement = document.createElement("div");
    containerElement.className = "mx-3 alert-block-wrapper";
    var icon = document.createElement("img");
    var closeIcon = document.createElement("img")
    closeIcon.className = 'close-icon';
    closeIcon.src = closeSvg;
    var alertMessage = document.createElement("p");
    alertMessage.className = "alertText";
    closeIcon.onclick = () => {
      AlertService.removeAlertBlock(alertBlockWithCloseButton);
    }
    if (type) {
      switch (type) {
        case 'info':
          alertBlockWithCloseButton.className = "cx--alert-block";
          icon.src = infoSvg;
          break;
        case 'success':
          alertBlockWithCloseButton.className = "cx--alert-block";
          icon.src = successSvg;
          break;
        case 'warning':
          alertBlockWithCloseButton.className = "cx--alert-block";
          icon.src = warningSvg;
          break;
        case 'error':
          alertBlockWithCloseButton.className = "cx--alert-block";
          icon.src = errorSvg;
          break;
        default:
          break
      }
    } else {
      alertBlockWithCloseButton.className = "cx--alert-block";
      icon.src = errorSvg;
    }
    alertMessage.innerHTML = respMessage;
    containerElement.append(icon);
    containerElement.append(alertMessage);
    containerElement.append(closeIcon);
    alertBlockWithCloseButton.append(containerElement);
    document.getElementById("cx--alert-container") && document.getElementById("cx--alert-container").append(alertBlockWithCloseButton);

    setTimeout(() => {
      AlertService.removeAlertBlock(alertBlockWithCloseButton)
    }, 5000);
  }

  static removeAlertBlock = (element) => {
    element && element.remove();
  }

  static alertConfirm = (title, message, yes, no) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="cx--modal-window">
              <div className="cx--modal-window-wrapper">
                <div className="cx--modal">
                  <div className="d-flex align-items-start">
                    <img src={warningSvg} alt="" />
                    <div className="d-block">
                      {
                        title ?
                          <strong className="cx--block-title">{title}</strong>
                          : null
                      }
                      {
                        message ?
                          <p>{message}</p>
                          : null
                      }
                    </div>
                  </div>
                  <div className="d-md-flex mt-3 justify-content-end">
                    <button className="cx--btn cx--btn-primary m-1" onClick={() => { resolve(); onClose(); }}>{yes}</button>
                    <button className="cx--btn cx--btn-danger-outline m-1" onClick={() => { reject(); onClose(); }}>{no}</button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })
    })
  }

}
