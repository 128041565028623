import React from "react";
import { BeatLoader } from "react-spinners";

function ButtonSpinner(props) {

  let color = "#ffffff";

  return (
    props.spinner && props.spinner.length ?
      <div className="sweet-loading d-flex">
        <BeatLoader size={10} color={color} />
      </div>
      : null
  );
}

export default ButtonSpinner;