import React from 'react';
import lottieJson from "./../../assets/videos/no-data.json"
import Lottie from 'react-lottie-player'

export default function NoData(props) {

  const { width, height } = props;

  return <div>
    <Lottie
      play
      loop
      animationData={lottieJson}
      style={{ width: width ? width : 300, height: height ? height : 300, margin: "0 auto" }}
    />
  </div>
}
