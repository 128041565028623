import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AboutUs from "../../Pages/AboutUs/AboutUs";
import Home from "../../Pages/Home/Home";
import SignIn from "../../Pages/SignIn/SignIn";
import SignUp from "../../Pages/SignUp/SignUp";
import ContactUs from './../../Pages/ContactUs/ContactUs';
import CreateAccountForm from '../../Pages/CreateAccountForm/CreateAccountForm';
import CookiesPolicy from "../../Pages/Resources/CookiesPolicy";
import PrivacyPolicy from "../../Pages/Resources/PrivacyPolicy";
import ComingSoon from "../../Pages/ComingSoon/ComingSoon";
import ForBusiness from "../../Pages/ForBusiness/ForBusiness";
import ShopWithCrypto from "../../Pages/ShopWithCrypto/ShopWithCrypto";
import SupportedCoins from "../../Pages/SupportedCoins/SupportedCoins";
import SupportedWallets from "../../Pages/SupportedWallets/SupportedWallets";
import WhyUs from "../../Pages/WhyUs/WhyUs";
import GetStarted from "../../Pages/GetStarted/GetStarted";
import Partners from "../../Pages/Partners/Partners";
import Blog from "../../Pages/Blog/Blog";
import IntegrationGuide from "../../Pages/IntegrationGuide/IntegrationGuide";
import DeveloperDocuments from "../../Pages/DeveloperDocuments/DeveloperDocuments";
import FAQ from "../../Pages/FAQ/FAQ";
import Help from "../../Pages/Help/Help";
import Solutions from "../../Pages/Solutions/Solutions";
import BecomeAnAgent from "../../Pages/BecomeAnAgent.js/BecomeAnAgent";


const UnAuthUserRouting = () => {
  return <Routes>
    <Route path="/" element={<Home />} />
    {/* <Route path="/sign-in" element={<SignIn />} /> */}
    <Route path="/sign-up" element={<SignUp />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/solutions" element={<Solutions />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/create-account" element={<CreateAccountForm />} />
    <Route path="/agent-registration" element={<BecomeAnAgent />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/cookies-policy" element={<CookiesPolicy />} />

    <Route path="/for-businesses" element={<ForBusiness />} />
    <Route path="/coming-soon" element={<ComingSoon />} />
    <Route path="/shop-with-crypto" element={<ShopWithCrypto />} />
    <Route path="/supported-coins" element={<SupportedCoins />} />
    <Route path="/supported-wallets" element={<SupportedWallets />} />
    <Route path="/why-us" element={<WhyUs />} />
    <Route path="/get-started" element={<GetStarted />} />
    <Route path="/partners" element={<Partners />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/integration-guide" element={<IntegrationGuide />} />
    <Route path="/developer-documents" element={<DeveloperDocuments />} />
    <Route path="/faq" element={<FAQ />} />
    <Route path="/help" element={<Help />} />

    <Route path="/:language/create-account" element={<CreateAccountForm />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
}

export default UnAuthUserRouting;
