import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from './Store/Actions/spinner';
import ApiService from './Services/ApiService';

const ErrorHandling = () => {
  let isPending = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
      if (!error || isPending) { return false; }
      isPending = true;
      sendError(msg, url, lineNo, columnNo, error);
    };
  }, []);

  const sendError = (msg, url, lineNo, columnNo, error) => {
    const spinnerId = uuid();
    const form = {
      column: columnNo,
      line: lineNo,
      url: url,
      message: msg,
      error: error,
    };
    dispatch(addPageSpinner(spinnerId));
    ApiService.sendError(form).then(() => {
      navigate(`/`);
      window.location.reload();
      dispatch(removePageSpinner(spinnerId));
    }).catch(error => {
      navigate(`/`);
      window.location.reload();
      dispatch(removePageSpinner(spinnerId));
    })
  };

  return null;
};

export default ErrorHandling;