import React, { useEffect } from "react"
import { useDispatch } from "react-redux";
import { logOut } from "../../Store/Actions/user";

const Signout = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logOut())
  })

  return <></>
}
export default Signout