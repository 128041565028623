import React, { useState } from 'react'
import MainService from './../../../Services/MainService';
import AlertService from './../../../Services/AlertService';
import { fielsLengths } from '../../../Constants/MainKeys';

export default function BusinessRepresentative(props) {

  const mainService = new MainService();
  const { values, setValues, isInvalidSubmit, onChange, receivedImage } = props;
  const VALID_IMAGE_TYPES_KEY = ['jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG'];

  const [uploadedFile, setUploadedFile] = useState(null);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);

  const checkPhoneNumber = (phoneNumber) => {
    if ((phoneNumber && (phoneNumber.length > fielsLengths.length_32 || phoneNumber.length < fielsLengths.length_6)) || !phoneNumber || phoneNumber.includes(".") || phoneNumber.includes("e")) {
      setIsInvalidPhoneNumber(true);
      return false;
    } else {
      setIsInvalidPhoneNumber(false);
      return false;
    }
  }

  const getToday = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    return today = yyyy + '-' + mm + '-' + dd;
  }

  const uploadFile = event => {
    if (!event.target.files[0]) { return; }
    const file = event.target.files[0];
    if (file) {
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
        setUploadedFile(uploadedImage);
        setValues((values) => ({
          ...values,
          logoImage: file,
        }));

      }).catch(error => error && AlertService.alert("error", "Invalid file type"));
    };
  }

  const removeUploadedFile = () => {
    setUploadedFile(null);
    setValues((values) => ({
      ...values,
      logoImage: null,
    }));
  }

  return (
    <div className="row">
      <div className='col-12 mb-3'>
        <h3>Business Representative</h3>
      </div>
      <div className="col-12">
        <div className='row'>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="personFullName" className='mb-1 cursor-pointer'>Legal Name of Person *</label>
            <input
              id="personFullName"
              type="text"
              name="personFullName"
              value={values.personFullName}
              className={`${(isInvalidSubmit && !values.personFullName.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "personFullName", fielsLengths.length_120)}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="email" className='mb-1 cursor-pointer'>Email Address *</label>
            <input
              id="email"
              type="email"
              name="email"
              value={values.email}
              className={`${(isInvalidSubmit && !values.email.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "email", fielsLengths.length_120)}
            />
          </div>
          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="date" className='mb-1 cursor-pointer'>DOB (date of birth) *</label>
            <input
              id="date"
              type="date"
              name="dob"
              min="1900-01-01"
              max={getToday()}
              value={values.dob}
              className={`${(isInvalidSubmit && !values.dob.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "dob")}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="homeAddress" className='mb-1 cursor-pointer'>Home Address</label>
            <input
              id="homeAddress"
              type="text"
              name="homeAddress"
              value={values.homeAddress}
              onChange={event => onChange(event, "homeAddress", fielsLengths.length_120)}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="phoneNumber" className='mb-1 cursor-pointer'>Phone Number</label>
            <input
              id="phoneNumber"
              type="tel"
              name="phoneNumber"
              value={values.phoneNumber}
              className={`${(isInvalidSubmit && (!values.phoneNumber.trim() || values.phoneNumber.trim().length <= 1)) || isInvalidPhoneNumber ? "cx--danger-border" : ""}`}
              onChange={event => {
                if (event.target.value.length) {
                  if ((event.target.value.length < fielsLengths.length_32 && typeof +event.target.value === "number" && Number(event.target.value)) || event.target.value === "+") {
                    setValues((values) => ({
                      ...values,
                      phoneNumber: event.target.value,
                    }));
                  } else { return; }
                } else {
                  setValues((values) => ({
                    ...values,
                    phoneNumber: "+",
                  }));
                }
              }}
              onBlur={(event) => {
                checkPhoneNumber(event.target.value);
              }}
            />
          </div>
          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="SSNNumber" className='mb-1 cursor-pointer'>Last 4 digits of SSN *</label>
            <input
              id="SSNNumber"
              type="text"
              name="SSNNumber"
              value={values.SSNNumber}
              className={`${(isInvalidSubmit && (!values.SSNNumber.trim().length || (values.SSNNumber.trim().length && values.SSNNumber.trim().length < fielsLengths.length_4))) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "SSNNumber")}
            />
          </div>
          <div className="col-lg-6 col-sm-12 mb-4">
            {
              receivedImage || uploadedFile ?
                <div className="col-lg-6 col-sm-12 mb-4">
                  <div className='logo-image-block' style={{ backgroundImage: `url(${uploadedFile ? uploadedFile : receivedImage ? receivedImage : null})` }} />
                </div>
                : null
            }
            {
              !uploadedFile ?
                <button onClick={MainService.triggerUploadClick} type="button" className="cx--btn cx--btn-primary">
                  <input type="file" id='avatarImage' className="d-none" hidden onChange={uploadFile} />
                  Logo image
                </button>
                :
                <button onClick={removeUploadedFile} type="button" className="cx--btn cx--btn-danger btn-sm btn-xs">
                  Remove
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
