import React from 'react'
import ActionButton from '../../../Components/Buttons/ActionButton';

export default function AddExtras(props) {

  const { values, onCheckboxChange } = props;


  return (
    <div className="row">
      <div className='col-12 mb-3'>
        <h3>Add Extras</h3>
      </div>
      <div className="col-12">
        <div className='row'>

          <div className="col-lg-6 col-sm-12 mb-4 d-flex align-items-baseline">
            <input
              id='isAutomateTaxCalculation'
              type="checkbox"
              name="isAutomateTaxCalculation"
              className='mx-2 d-block'
              checked={values.isAutomateTaxCalculation}
              onChange={event => onCheckboxChange(event, "isAutomateTaxCalculation")}
            />
            <label htmlFor='isAutomateTaxCalculation' className='mb-1 cursor-pointer'>Automated Sales Tax Calculation</label>
          </div>
          <div htmlFor="isClimateControbution" className="col-lg-6 col-sm-12 mb-4 d-flex align-items-baseline">
            <input
              id='isClimateControbution'
              type="checkbox"
              name="isClimateControbution"
              className='mx-2 d-block'
              checked={values.isClimateControbution}
              onChange={event => onCheckboxChange(event, "isClimateControbution")}
            />
            <label htmlFor='isClimateControbution' className='mb-1 cursor-pointer'>Climate Controbutions</label>
          </div>

        </div>
      </div>
    </div>
  )
}
