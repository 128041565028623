import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UnAuthUsersLayout from "./Layouts/UnAuthUsersLayout/UnAuthUsersLayout";
import AuthUsersLayout from "./Layouts/AuthUsersLayout/AuthUsersLayout.js";
import { useSelector } from 'react-redux';
import PageSpinner from "./Components/Spinners/PageSpinner";
import ScrollTop from "./Components/ScrollToTop/scrollTop";
import CookiesModal from "./Components/CookiesModal/CookiesModal";
import ErrorHandling from "./ErrorHandling";

const App = () => {

  const { pathname } = useLocation();
  const { user, token } = useSelector(state => state.user);
  const pageSpinners = useSelector(state => state.spinner.pageSpinners);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return <React.Fragment>
    {
      user
        ? <AuthUsersLayout />
        : <UnAuthUsersLayout />
    }
    <div id="cx--alert-container" className='cx--alert-container' />
    <PageSpinner spinner={pageSpinners} />
    <ScrollTop />
    <ErrorHandling />
    <CookiesModal />
  </React.Fragment>
}

export default App;
