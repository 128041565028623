import {
  AUTH_SUCCESS,
  CURRENT_USER_SUCCESS,
  AUTH_FAIL,
  LOG_OUT,
  REMOVE_LOGIN_ERROR_MESSAGE
} from "../../Constants/ReduxKeys";

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  if (typeof JSON.parse(str) !== "object") {
    return false;
  }
  return true;
}

const initialState = {
  token: localStorage.getItem("token"),
  refreshToken: localStorage.getItem("refreshToken"),
  user: localStorage.getItem("user") && isJson(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null,
  loginErrorMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      localStorage.setItem("token", action.payload.token.accessToken);
      action.payload.refreshToken && localStorage.setItem("refreshToken", action.payload.refreshToken);
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
      };
    case CURRENT_USER_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_FAIL:
      return {
        ...state,
        loginErrorMessage: action.payload,
      };

    case REMOVE_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        loginErrorMessage: ''
      };
    case LOG_OUT:
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        user: null,
        token: null,
        refreshToken: null,
      };

    default:
      break;
  }
  return state;
};

export default reducer;