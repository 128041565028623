import React, { useState, useEffect, useCallback } from 'react'
import uuid from 'react-uuid';
import ActionButton from '../../Components/Buttons/ActionButton';
import FormSpinner from '../../Components/Spinners/FormSpinner';
import AlertService from '../../Services/AlertService';
import ApiService from '../../Services/ApiService';
import { addButtonSpinner, removeButtonSpinner } from '../../Store/Actions/spinner';
import AddExtras from './Components/AddExtras';
import BusinessDetails from './Components/BusinessDetails';
import BusinessRepresentative from './Components/BusinessRepresentative';
import BusinessType from './Components/BusinessType';
import FulfillmentDetails from './Components/FulfillmentDetails';
// import MerchantBankDetails from './Components/MerchantBankDetails';
import PublicDetailsForCustomers from './Components/PublicDetailsForCustomers';
import SecureYourAccount from './Components/SecureYourAccount';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

export default function Business() {

  const dispatch = useDispatch();
  const buttonSpinnerId = uuid();
  const { user } = useSelector(state => state.user);
  const [activeTab, setActiveTab] = useState('1');
  const [isShowSpinner, setIsShowSpinner] = useState(false);

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [receivedImage, setReceivedImage] = useState(false);

  const [industryLibrary, setIndustryLibrary] = useState([]);
  const [customersTypeLibrary, setCustomersTypeLibrary] = useState([]);
  const [sellTypeLibrary, setSellTypeLibrary] = useState([]);
  const [structureLibrary, setStructureLibrary] = useState([]);
  const [typeLibrary, setTypeLibrary] = useState([]);

  const [values, setValues] = useState({
    id: null,
    organizationId: null,
    organizationName: '',
    employerNumber: '',
    DBAName: '',
    industryLibraryDataId: 1,
    websiteUrl: '',
    description: '',
    typeLibraryDataId: 1,
    structureLibraryDataId: 2,
    registerAddress: '',
    customersTypeLibraryDataId: 1,
    sellTypeLibraryDataId: 2,
    personFullName: '',
    email: '',
    dob: '',
    homeAddress: '',
    phoneNumber: '',
    SSNNumber: '',
    logoImage: null,
    statementDescriptor: '',
    shortenDescriptor: '',
    supportNumber: '',
    supportAddress: '',
    useSMS: false,
    isAutomateTaxCalculation: false,
    isClimateControbution: false,
  });

  const getUrganizationData = () => {
    setIsShowSpinner(true);
    ApiService.getUrganizationData().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.customersTypeLibrary && data.customersTypeLibrary.libraryData) {
          setCustomersTypeLibrary(data.customersTypeLibrary.libraryData);
          setValues((values) => ({
            ...values,
            customersTypeLibraryDataId: data.customersTypeLibraryDataId ? data.customersTypeLibraryDataId : data.customersTypeLibrary.libraryData[0].id,
          }));
        }
        if (data.industryLibrary && data.industryLibrary.libraryData) {
          setIndustryLibrary(data.industryLibrary.libraryData);
          setValues((values) => ({
            ...values,
            industryLibraryDataId: data.industryLibraryDataId ? data.industryLibraryDataId : data.industryLibrary.libraryData[0].id,
          }));
        }
        if (data.sellTypeLibrary && data.sellTypeLibrary.libraryData) {
          setSellTypeLibrary(data.sellTypeLibrary.libraryData);
          setValues((values) => ({
            ...values,
            sellTypeLibraryDataId: data.sellTypeLibraryDataId ? data.sellTypeLibraryDataId : data.sellTypeLibrary.libraryData[0].id,
          }));
        }
        if (data.structureLibrary && data.structureLibrary.libraryData) {
          setStructureLibrary(data.structureLibrary.libraryData);
          setValues((values) => ({
            ...values,
            structureLibraryDataId: data.structureLibraryDataId ? data.structureLibraryDataId : data.structureLibrary.libraryData[0].id,
          }));
        }
        if (data.typeLibrary && data.typeLibrary.libraryData) {
          setTypeLibrary(data.typeLibrary.libraryData);
          setValues((values) => ({
            ...values,
            typeLibraryDataId: data.typeLibraryDataId ? data.typeLibraryDataId : data.typeLibrary.libraryData[0].id,
          }));
        }

        setValues((values) => ({
          ...values,
          id: data.id,
          organizationId: data.organizationId,
          organizationName: data.organizationName ? data.organizationName : "",
          employerNumber: data.employerNumber ? data.employerNumber : 0,
          DBAName: data.dbaName ? data.dbaName : "",
          websiteUrl: data.websiteUrl ? data.websiteUrl : "",
          description: data.description ? data.description : "",
          registerAddress: data.registerAddress ? data.registerAddress : "",
          personFullName: data.personFullName ? data.personFullName : "",
          email: data.email ? data.email : "",
          dob: data.dob ? moment(new Date(data.dob)) : "",//???
          homeAddress: data.homeAddress ? data.homeAddress : "",
          phoneNumber: data.phoneNumber ? data.phoneNumber : "",
          SSNNumber: data.ssnNumber ? data.ssnNumber : "",
          statementDescriptor: data.statementDescriptor ? data.statementDescriptor : "",
          shortenDescriptor: data.shortenDescriptor ? data.shortenDescriptor : "",
          supportNumber: data.supportNumber ? data.supportNumber : "",
          supportAddress: data.supportAddress ? data.supportAddress : "",
          useSMS: data.useSMS,
          isAutomateTaxCalculation: !!data.isAutomateTaxCalculation, //???
          isClimateControbution: !!data.isClimateControbution,//???
        }));

        if (data.logoUrl) {
          setReceivedImage(data.logoUrl);
        }

      }
      setIsShowSpinner(false);
    }).catch(error => getFail(error))
  }

  useEffect(() => {
    getUrganizationData();
  }, [])


  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
  }

  const onSelectOptionChange = (value, field) => {
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
  }

  const onCheckboxChange = (event, field) => {
    if (typeof event.target.checked === "boolean") {
      setValues((values) => ({
        ...values,
        [field]: event.target.checked,
      }));
    }
  }

  const onNumberChange = (event, field) => {
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes("e")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) > 0)) {
      setValues((values) => ({
        ...values,
        [field]: +event.target.value,
      }));
    }
  };

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    const formData = new FormData();
    for (let i in values) {
      formData.append(`${i}`, values[i]);
    }
    ApiService.organizationUpdate(formData).then(response => {
      extractButtonSpinner(buttonSpinnerId);
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractButtonSpinner(spinnerId);
    setIsShowSpinner(false);
  };


  return (
    <section className="cx--section" style={{ marginTop: "80px" }}>
      <div className="container">
        <form onSubmit={onSubmit}>
          <div className='row business-form'>
            <div className='col-md-3 col-sm-12 left-section py-2'>
              <div className="tab w-100">
                {/* cx--danger-border-2 */}
                <button type='button' className={`tablinks my-1 ${activeTab === "1" ? "active" : ""}`} onClick={() => setActiveTab('1')}>Business Details</button>
                <button type='button' className={`tablinks my-1 ${activeTab === "2" ? "active" : ""}`} onClick={() => setActiveTab('2')}>Business Type</button>
                <button type='button' className={`tablinks my-1 ${activeTab === "3" ? "active" : ""}`} onClick={() => setActiveTab('3')}>Business Representative</button>
                <button type='button' className={`tablinks my-1 ${activeTab === "4" ? "active" : ""}`} onClick={() => setActiveTab('4')}>Fulfillment Details</button>
                <button type='button' className={`tablinks my-1 ${activeTab === "5" ? "active" : ""}`} onClick={() => setActiveTab('5')}>Public Details for Customers</button>
                {/* <button className={`tablinks my-1 ${activeTab === "6" ? "active" : ""}`} onClick={() => setActiveTab('6')}>Merchant Bank Details</button> */}
                <button type='button' className={`tablinks my-1 ${activeTab === "7" ? "active" : ""}`} onClick={() => setActiveTab('7')}> Secure your account</button>
                <button type='button' className={`tablinks my-1 ${activeTab === "8" ? "active" : ""}`} onClick={() => setActiveTab('8')}>Add Extras</button>
              </div>
            </div>
            <div className='col-md-9 col-sm-12 right-section position-relative'>
              <div id="1" className={`tabcontent ${activeTab === '1' ? "d-block" : "d-none"}`}>
                <BusinessDetails
                  values={values}
                  isInvalidSubmit={isInvalidSubmit}
                  industryLibrary={industryLibrary}
                  onChange={onChange}
                  onNumberChange={onNumberChange}
                  onSelectOptionChange={onSelectOptionChange}
                />
              </div>
              <div id="2" className={`tabcontent ${activeTab === '2' ? "d-block" : "d-none"}`}>
                <BusinessType
                  values={values}
                  typeLibrary={typeLibrary}
                  structureLibrary={structureLibrary}
                  isInvalidSubmit={isInvalidSubmit}
                  onSelectOptionChange={onSelectOptionChange}
                  onChange={onChange}
                />
              </div>

              <div id="3" className={`tabcontent ${activeTab === '3' ? "d-block" : "d-none"}`}>
                <BusinessRepresentative
                  values={values}
                  isInvalidSubmit={isInvalidSubmit}
                  receivedImage={receivedImage}
                  onChange={onChange}
                  setValues={setValues}
                />
              </div>
              <div id="4" className={`tabcontent ${activeTab === '4' ? "d-block" : "d-none"}`}>
                <FulfillmentDetails
                  values={values}
                  customersTypeLibrary={customersTypeLibrary}
                  sellTypeLibrary={sellTypeLibrary}
                  onSelectOptionChange={onSelectOptionChange}
                />
              </div>
              <div id="5" className={`tabcontent ${activeTab === '5' ? "d-block" : "d-none"}`}>
                <PublicDetailsForCustomers
                  values={values}
                  isInvalidSubmit={isInvalidSubmit}
                  onNumberChange={onNumberChange}
                  onChange={onChange}
                />
              </div>
              {/* <div id="6" className={`tabcontent ${activeTab === '6' ? "d-block" : "d-none"}`}>
              <MerchantBankDetails />
            </div> */}
              <div id="7" className={`tabcontent ${activeTab === '7' ? "d-block" : "d-none"}`}>
                <SecureYourAccount
                  values={values}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              <div id="8" className={`tabcontent ${activeTab === '8' ? "d-block" : "d-none"}`}>
                <AddExtras
                  values={values}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              {
                isShowSpinner ?
                  <FormSpinner blockClassName="position-absolute" />
                  : null
              }
            </div>
          </div>
          <div className='row'>
            <div className='col-12 p-0 d-flex justify-content-end'>
              <ActionButton
                name="Save"
                type="submit"
                className="cx--btn cx--btn-primary mt-3"
                spinnerId={buttonSpinnerId}
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}
