
import { takeLatest, call, put } from 'redux-saga/effects';
import ApiService from '../../Services/ApiService';
import AlertService from "../../Services/AlertService";
import { ERROR_KEY } from "../../Constants/MainKeys";
import { GET_PAGE_CONTENT_BY_ID, SET_PAGE_CONTENT } from "../../Constants/ReduxKeys";
import { setIsShowPageContentSpinner } from '../Actions/pageContent';

export default function* watcher() {
  yield takeLatest(GET_PAGE_CONTENT_BY_ID, getPageContentById);
};

function* getPageContentById(action) {
  const id = action.payload;
  try {
    yield put(setIsShowPageContentSpinner(true));
    const pageContent = yield call(ApiService.getPageContentById, id);
    yield put({
      type: SET_PAGE_CONTENT,
      payload: pageContent ? pageContent.data : null,
    });
    yield put(setIsShowPageContentSpinner(false));
  } catch (e) {
    yield put(setIsShowPageContentSpinner(false));
    AlertService.alert(e, ERROR_KEY);
  };
};


