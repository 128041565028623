import React, { useCallback, useEffect, useState } from 'react'
import darkLogoSvg from "./../../../assets/logo/dark-logo.svg";
import ApiService from '../../../Services/ApiService';
import uuid from 'react-uuid';
import { useDispatch } from 'react-redux';
import { addPageSpinner, removePageSpinner } from '../../../Store/Actions/spinner';
import AlertService from '../../../Services/AlertService';
import { Link } from 'react-router-dom';
import { HiOutlineMail } from "react-icons/hi";
import FacebookLogo from "./../../../assets/icons/social-media/facebook.svg";
import LinkedinLogo from "./../../../assets/icons/social-media/linkedin.svg";
import TwitterLogo from "./../../../assets/icons/social-media/twitter.svg";
import YoutubeLogo from "./../../../assets/icons/social-media/youtube.svg";
import { MdEmail } from "react-icons/md";

export default function Footer() {

  const dispatch = useDispatch();
  const [footerData, setFooterData] = useState([]);

  const sctollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getFooterData().then(response => {
      if (response && response.data) {
        setFooterData(response.data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }, [])

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractPageSpinner(spinnerId);
  };

  return (
    <>

      <footer className="cx--section cx--gray-background">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 my-0 my-sm-4 col-md-6 my-md-0 my-3">
              <div className="cx--footer-logo">
                <Link to="/" onClick={sctollToTop}><img src={darkLogoSvg} alt="dark-logo" /></Link>
              </div>
            </div>

            <div className="col-lg-3 my-0 my-sm-4 col-md-6 my-md-0 my-3">
              <strong className="cx--footer-title">Contact us</strong>
              <ul className="cx--footer-list">
                <li className="cx--footer-list-item">
                  <a href="mailto:info@cryllex.com" className='d-flex align-items-center'>
                    <MdEmail className='me-1' />
                    info@cryllex.com
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 my-0 my-sm-4 col-md-6 my-md-0 my-3">
              <strong className="cx--footer-title">Socials</strong>
              <ul className="cx--footer-social-list">
                {/* <li className="cx--footer-social-item">
                <a href="https://www.facebook.com" target="_blank"><img src={FacebookLogo} alt="/" /></a>
              </li>
              <li className="cx--footer-social-item">
                <a href="https://www.youtube.com" target="_blank"><img src={YoutubeLogo} alt="/" /></a>
              </li> */}
                <li className="cx--footer-social-item" title='Linkedin'>
                  <a href="https://www.linkedin.com" target="_blank"><img src={LinkedinLogo} alt="/" /></a>
                </li>
                <li className="cx--footer-social-item" title='Twitter'>
                  <a href="https://twitter.com" target="_blank"><img src={TwitterLogo} alt="/" /></a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 my-0 my-sm-3 col-md-6 my-md-0 my-3">
              <strong className="cx--footer-title">Resources</strong>
              <ul className="cx--footer-list">
                <li className="cx--footer-list-item"><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li className="cx--footer-list-item"><Link to="/cookies-policy">Cookies Policy</Link></li>
                <li className="cx--footer-list-item"><Link to="/agent-registration">For Business</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className='cx--relamy-copyright'>
        <small>Copyright © {new Date().getFullYear()} Cryllex | Powered by Cryllex</small>
      </div>
    </>
  )
}
