import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import watchers from './Store/Sagas';
import reducer from './Store/Reducers';
import "./css/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-multi-carousel/lib/styles.css";


const saga = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware(), saga]
})

saga.run(watchers);
window.Storage = store;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
