import {
  USER_LOGIN_REQUEST,
  USER_REGISTRATION_REQUEST,
  LOG_OUT,
  REMOVE_LOGIN_ERROR_MESSAGE
} from '../../Constants/ReduxKeys';

export function userLogin(data, spinnerId) {
  return {
    type: USER_LOGIN_REQUEST,
    payload: { data, spinnerId },
  };
};

export const removeLoginErrorMessage = () => {
  return {
    type: REMOVE_LOGIN_ERROR_MESSAGE,
  }
}

export function userRegistration(data, spinnerId) {
  return {
    type: USER_REGISTRATION_REQUEST,
    payload: { data, spinnerId },
  };
};

export function logOut() {
  return {
    type: LOG_OUT,
    payload: null,
  };
};


