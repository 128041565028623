import React, { useState, useEffect, useRef, useCallback } from 'react';
import mainLogo from "./../../../assets/logo/main-logo.svg";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addPageSpinner, removePageSpinner } from '../../../Store/Actions/spinner';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import ApiService from '../../../Services/ApiService';
import { Accordion } from 'react-bootstrap';
import MainService from '../../../Services/MainService';

function useOutsideAlerter(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        var rightMenuCheckBox = document.getElementById("right-menu");
        rightMenuCheckBox.checked = false;
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function UnauthHeader() {

  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [headerData, setHeaderData] = useState([]);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  const [showSolutions, setShowSolutions] = useState(false);
  const [showPersonal, setShowPersonal] = useState(false);
  const [showBusinesses, setShowBusinesses] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);


  function handleMouseEnter(menu) {
    switch (menu) {
      case 'solutions':
        setShowSolutions(true);
        break;
      case 'personal':
        setShowPersonal(true);
        break;
      case 'businesses':
        setShowBusinesses(true);
        break;
      case 'company':
        setShowCompany(true);
        break;
      case 'resources':
        setShowResources(true);
        break;
      case 'account':
        setShowAccount(true);
        break;
      default:
        break;
    }
  }

  function handleMouseLeave(menu) {
    switch (menu) {
      case 'solutions':
        setShowSolutions(false);
        break;
      case 'personal':
        setShowPersonal(false);
        break;
      case 'businesses':
        setShowBusinesses(false);
        break;
      case 'company':
        setShowCompany(false);
        break;
      case 'resources':
        setShowResources(false);
        break;
      case 'account':
        setShowAccount(false);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    getHeaderData();
  }, [])

  const getHeaderData = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getUnAuthHeader().then(response => {
      if (response && response.data) {
        setHeaderData(response.data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }


  const closeRightMenu = () => {
    var rightMenuCheckBox = document.getElementById("right-menu");
    rightMenuCheckBox.checked = false;
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractPageSpinner(spinnerId);
  };

  var isShowActiveHeader = (
    offset > 100 ||
    window.location.pathname === "/contact-us" ||
    window.location.pathname === "/test-form" ||
    window.location.pathname === "/privacy-policy" ||
    window.location.pathname === "/cookies-policy" ||
    window.location.pathname === "/for-businesses" ||
    window.location.pathname === "/coming-soon" ||
    window.location.pathname === "/shop-with-crypto" ||
    window.location.pathname === "/supported-coins" ||
    window.location.pathname === "/supported-wallets" ||
    window.location.pathname === "/why-us" ||
    window.location.pathname === "/get-started" ||
    window.location.pathname === "/partners" ||
    window.location.pathname === "/blog" ||
    window.location.pathname === "/integration-guide" ||
    window.location.pathname === "/developer-documents" ||
    window.location.pathname === "/faq" ||
    window.location.pathname === "/help"
  )
    && window.location.pathname !== "/sign-in";


  return headerData && headerData.length ? <header className={isShowActiveHeader ? "cx--header-active" : ""}>
    <nav className="navbar navbar-expand-lg bg-transparent">
      <div className="container">
        <div className="d-block">
          <Link
            className="navbar-brand navbar-brand-colored"
            to="/"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <img id="brand-logo" src={mainLogo} alt="colored-logo" />
          </Link>
        </div>
        <div className="cx--burger-menu-wrapper" ref={wrapperRef}>
          <div className="cx--unsigned-customer">
            <input id='right-menu' type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <div className="cx--burger-menu">
              <ul>
                <Accordion className='cx--nav-accordion' flush>
                  <Accordion.Header className='cx--nav-accordion-header button-without-icon'>
                    <li className="cx--nav-burger-item">
                      <Link className="cx--nav-burger-link" to="/" onClick={closeRightMenu}>Homepage</Link>
                    </li>
                  </Accordion.Header>
                  <Accordion.Header className='cx--nav-accordion-header button-without-icon'>
                    <li className="cx--nav-burger-item">
                      <Link className="cx--nav-burger-link" to="/solutions" onClick={closeRightMenu}>Solutions</Link>
                    </li>
                  </Accordion.Header>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Company</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/about-us" onClick={closeRightMenu}>About</Link>
                      </li>
                      {/* <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/partners" onClick={closeRightMenu}>Partners</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/blog" onClick={closeRightMenu}>Blog</Link>
                      </li> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Resources</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/integration-guide" onClick={closeRightMenu}>Integration Guide</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/developer-documents" onClick={closeRightMenu}>Developer Documents</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/faq" onClick={closeRightMenu}>FAQs</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/privacy-policy" onClick={closeRightMenu}>Privacy Policy</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/cookies-policy" onClick={closeRightMenu}>Cookie Policy</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/contact-us" onClick={closeRightMenu}>Contact Us</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/help" onClick={closeRightMenu}>Help</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Account</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <a className="cx--nav-burger-link" href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`} onClick={closeRightMenu}>Sign In</a>
                      </li>
                   
                      <li className="cx--nav-burger-item">
                        {/* <Link className="cx--nav-burger-link" to="/sign-up" onClick={closeRightMenu}>Create an Account</Link> */}
                        <Link className="cx--nav-burger-link" to="/create-account" onClick={closeRightMenu} style={{textTransform:"inherit"}}>Become a Merchant</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* <Accordion.Item eventKey="0">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Solutions</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/for-businesses" onClick={closeRightMenu}>For Businesses</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/coming-soon" onClick={closeRightMenu}>Coming soon</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="1">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Personal</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/shop-with-crypto" onClick={closeRightMenu}>Shop Online with your Crypto</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/supported-coins" onClick={closeRightMenu}>Supported Coins</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/supported-wallets" onClick={closeRightMenu}>Supported Wallets</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="2">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Businesses</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/why-us" onClick={closeRightMenu}>Why us?</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/get-started" onClick={closeRightMenu}>Get Started</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="3">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Company</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/about-us" onClick={closeRightMenu}>About</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/partners" onClick={closeRightMenu}>Partners</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/blog" onClick={closeRightMenu}>Blog</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="4">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Resources</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">

                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/integration-guide" onClick={closeRightMenu}>Integration Guide</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/developer-documents" onClick={closeRightMenu}>Developer Documents</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/faq" onClick={closeRightMenu}>FAQs</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/privacy-policy" onClick={closeRightMenu}>Privacy Policy</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/cookies-policy" onClick={closeRightMenu}>Cookie Policy</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/contact-us" onClick={closeRightMenu}>Contact Us</Link>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/help" onClick={closeRightMenu}>Help</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header className='cx--nav-accordion-header'>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="#">Account</Link>
                      </li>
                    </Accordion.Header>
                    <Accordion.Body className="cx--nav-accordion-body">
                      <li className="cx--nav-burger-item">
                        <a className="cx--nav-burger-link" href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`} onClick={closeRightMenu}>Sign In</a>
                      </li>
                      <li className="cx--nav-burger-item">
                        <Link className="cx--nav-burger-link" to="/sign-up" onClick={closeRightMenu}>Create an Account</Link>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </ul>
            </div>
          </div>
        </div>
        <div className="cx--screen-menu">
          <div className="navbar-nav align-items-center">
            <ul className='header'>
              <li>
                <Link to="/" className='main-link'>Homepage</Link>
              </li>
              <li>
                <Link to="/solutions" className='main-link'>Solutions</Link>
              </li>
              {/* <li
                onMouseEnter={() => handleMouseEnter('solutions')}
                onMouseLeave={() => handleMouseLeave('solutions')}
              >
                Solutions
                {showSolutions && (
                  <ul className="dropdown">
                    <li>
                      <Link className="nav-link" to="/for-businesses" onClick={() => handleMouseLeave('solutions')}>For Businesses</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/coming-soon" onClick={() => handleMouseLeave('solutions')}>Coming soon</Link>
                    </li>
                  </ul>
                )}
              </li> */}
              {/* <li
                onMouseEnter={() => handleMouseEnter('personal')}
                onMouseLeave={() => handleMouseLeave('personal')}
              >
                Personal
                {showPersonal && (
                  <ul className="dropdown">
                    <li>
                      <Link className="nav-link" to="/shop-with-crypto" onClick={() => handleMouseLeave('personal')}>Shop Online with your Crypto</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/supported-coins" onClick={() => handleMouseLeave('personal')}>Supported Coins</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/supported-wallets" onClick={() => handleMouseLeave('personal')}>Supported Wallets</Link>
                    </li>
                  </ul>
                )}
              </li> */}
              {/* <li
                onMouseEnter={() => handleMouseEnter('businesses')}
                onMouseLeave={() => handleMouseLeave('businesses')}
              >
                Businesses
                {showBusinesses && (
                  <ul className="dropdown">
                    <li>
                      <Link className="nav-link" to="/why-us" onClick={() => handleMouseLeave('businesses')}>Why us?</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/get-started" onClick={() => handleMouseLeave('businesses')}>Get Started</Link>
                    </li>
                  </ul>
                )}
              </li> */}
              <li
                onMouseEnter={() => handleMouseEnter('company')}
                onMouseLeave={() => handleMouseLeave('company')}
              >
                Company
                {showCompany && (
                  <ul className="dropdown">
                    <li>
                      <Link className="nav-link" to="/about-us" onClick={() => handleMouseLeave('company')}>About</Link>
                    </li>
                    {/* <li>
                      <Link className="nav-link" to="/partners" onClick={() => handleMouseLeave('company')}>Partners</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/blog" onClick={() => handleMouseLeave('company')}>Blog</Link>
                    </li> */}
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('resources')}
                onMouseLeave={() => handleMouseLeave('resources')}
              >
                Resources
                {showResources && (
                  <ul className="dropdown">
                    <li>
                      <Link className="nav-link" to="/integration-guide" onClick={() => handleMouseLeave('resources')}>Integration Guide</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/developer-documents" onClick={() => handleMouseLeave('resources')}>Developer Documents</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/faq" onClick={() => handleMouseLeave('resources')}>FAQs</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/privacy-policy" onClick={() => handleMouseLeave('resources')}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/cookies-policy" onClick={() => handleMouseLeave('resources')}>Cookie Policy</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/contact-us" onClick={() => handleMouseLeave('resources')}>Contact Us</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/help" onClick={() => handleMouseLeave('resources')}>Help</Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('account')}
                onMouseLeave={() => handleMouseLeave('account')}
              >
                Account
                {showAccount && (
                  <ul className="dropdown">
                    <li>
                      <a className="nav-link" href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`} onClick={() => handleMouseLeave('account')}>Sign In</a>
                    </li>
                    <li>
                      {/* <a className="nav-link" href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/create-account`} onClick={() => handleMouseLeave('account')}>Create an Account</a> */}
                      <Link className="nav-link" to={`/create-account`} onClick={() => handleMouseLeave('account')} style={{ textTransform: "inherit" }}>Become a Merchant</Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div >
    </nav >
  </header> : null
}
