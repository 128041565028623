import React from 'react'
import groupSvg from "./../../assets/images/group.svg"
import coverSvg from "./../../assets/images/decors/home-cover-decor.svg"
import speedSvg from "./../../assets/icons/w35/speed.svg"
import protectionSvg from "./../../assets/icons/h70/protection.svg"
import costSvg from "./../../assets/icons/w35/cost.svg"
import transferSvg from "./../../assets/icons/w35/transfer.svg"
import conversionSvg from "./../../assets/icons/w35/conversion.svg"
import paymentSvg from "./../../assets/icons/w35/payment.svg"
import invoiceSvg from "./../../assets/icons/w35/invoice.svg"
import { useNavigate } from 'react-router'
import ContactLaptopSvg from "./../../assets/images/contact-laptop.svg"
import decor4x4Svg from "./../../assets/images/decors/decor-4x4.svg"
import checkSvg from "./../../assets/icons/check-primary.svg"
import MainService from '../../Services/MainService'

export default function Solutions() {

    const navigate = useNavigate();

    return <React.Fragment>
        <section className="cx--cover-container">
            <img className="cx--cover-decor" src={coverSvg} alt="cover" />
            <div className="container">
                <div className="cx--cover-body-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <h1 className="cx--cover-title">
                                An arsenal of <br />
                                <span className="cx--secondary-color">financial instruments</span> <br />
                                at your fingertips
                            </h1>
                            <p className="cx--cover-text mt-3">
                                Cryllex develops practical applications to elevate businesses and
                                individuals across the globe. An agenda that increases the
                                accessibility and ubiquity of revolutionary technologies, swiftly,
                                cheaply and securely.
                            </p>
                            <div className="cx--btn-inline-group mt-3">
                                {/* <button className="cx--btn cx--btn-primary cx--text-transform-initial ms-0" type="button">Our Solutions</button>
                                <button className="cx--btn cx--btn-primary-outline cx--text-transform-initial ms-0" type="button">Start Now</button> */}
                                <button
                                    className="cx--btn cx--btn-primary cx--text-transform-initial ms-0"
                                    type="button"
                                    onClick={() => {
                                        navigate("/create-account")
                                    }}
                                >
                                    Start Now
                                </button>
                                {/* <button className="cx--btn cx--btn-primary-outline cx--text-transform-initial ms-0" type="button">Start Now</button> */}
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="cx--cover-image">
                                <img src={groupSvg} alt="about-us-cover" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section cx--primary-background position-relative">
            <img className="cx--section-decoration cx--section-decoration-left" src={decor4x4Svg} alt="decoration" />
            <div className="container">
                <div className="cx--cover-bottom-container cx--sm-section">
                    <div className="cx--cover-bottom-title-wrapper">
                        <div className='row'>
                            <div className='col-12'>
                                <div className="cx--cover-bottom-title">
                                    <h2>Your go-to provider of enterprise-grade
                                        digital payment technologies
                                    </h2>
                                    <p className='cx--text-mute mt-4'>
                                        We help you access the diverse benefits of blockchain technology and digital
                                        assets with near none of the limitations, while improving your bottom line, all
                                        through a simple API integration.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section mt-5">
            <div className="container">
                <div className="row">
                    <div className="cx--section-title-wrapper">
                        <h2 className="cx--section-title cx--section-dark-title cx--text-transform-initial">
                            An array of use cases that caters to <br />
                            our global consumer base
                        </h2>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-4 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={protectionSvg} alt="protection" />
                                </div>
                                <h5 className="cx--card-title">eCommerce Payment Processing</h5>
                            </div>
                            <p className="cx--card-body">
                                A crypto-to-fiat payment processing platform that allows eCommerce businesses to enjoy greater revenue at a cheaper cost.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={speedSvg} alt="speed" />
                                </div>
                                <h5 className="cx--card-title">Corporate Link-Based Payments</h5>
                            </div>
                            <p className="cx--card-body">
                                One-time payment links that enable traditional businesses to receive crypto or fiat payments, in a cheap and secure manner - instantly.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={costSvg} alt="cost" />
                                </div>
                                <h5 className="cx--card-title">Cross-Border Payments</h5>
                            </div>
                            <p className="cx--card-body">
                                Send money from anywhere to
                                anywhere. Save money on transaction
                                fees and make payments whenever
                                you need.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section cx--primary-background position-relative">
            <img className="cx--section-decoration cx--section-decoration-left" src={decor4x4Svg} alt="decoration" />

            <div className="container">
                <div className="cx--cover-bottom-container cx--sm-section">
                    <div className="cx--cover-bottom-title-wrapper">
                        <div className="cx--cover-bottom-title">
                            <h2>Accept digital currencies, settle in fiat</h2>
                            <p className='cx--text-mute mt-4'>
                                Enjoy the option to accept digital currencies while receiving settlement in the fiat
                                currency of your choice.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={invoiceSvg} alt="protection" />
                                </div>
                                <h5 className="cx--card-title">Invoice</h5>
                            </div>
                            <p className="cx--card-body">
                                Upon checkout on the merchant site, the shopper is directed to a simple and straight forward Cryllex payment popup window.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 my-2 position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={paymentSvg} alt="speed" />
                                </div>
                                <h5 className="cx--card-title">Payment Selection</h5>
                            </div>
                            <p className="cx--card-body">
                                The shopper selects a currency of
                                their choice at an exchange rate
                                that will be guaranteed and
                                locked in for the duration of the
                                transaction.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 my-2  position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={conversionSvg} alt="cost" />
                                </div>
                                <h5 className="cx--card-title">Currency Conversion</h5>
                            </div>
                            <p className="cx--card-body">
                                Following a request for settlement, Cryllex exchanges the digital currency for your preferred choice of fiat currency.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 my-2  position-relative">
                        <div className="cx--card cx--bordered-card-wrapper">
                            <div className="cx--card-title-wrapper">
                                <div className="cx--card-icon">
                                    <img src={transferSvg} alt="cost" />
                                </div>
                                <h5 className="cx--card-title">Transfer</h5>
                            </div>
                            <p className="cx--card-body">
                                Cryllex transfers the amount due
                                directly into your bank account.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section cx--gray-background position-relative">
            <div className="container">
                <div className="row align-items-center">
                    <div className="cx--section-title-wrapper mb-5">
                        <h2 className="cx--section-title cx--section-dark-title cx--text-transform-initial">
                            <span>Differentiate</span> from your competition
                        </h2>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <ul className="cx--list-wrapper">
                                <li className="cx--list cx--large-list" style={{ minHeight: "200px" }}>
                                    <img className="cx--primary-color" src={checkSvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Lightning fast transaction and settlement times</h6>
                                        <p>
                                            Through our seamless API integration, you can embrace the rise of a new
                                            form of money and accept payments regardless of the time and location.
                                            Our infrastructure swiftly handles domestic and global payments alike,
                                            processing payments instantaneously with end-of-day settlements,
                                            secured using blockchain technology.
                                        </p>
                                    </div>
                                </li>
                                <li className="cx--list cx--large-list" style={{ minHeight: "200px" }}>
                                    <img className="cx--primary-color" src={checkSvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>An iota of the fees you’re used to paying</h6>
                                        <p>
                                            Transactions are subject to less than 5% of the fees charged by traditional
                                            payment networks, enabling merchants to enjoy greater profit margins.
                                        </p>
                                    </div>
                                </li>
                                <li className="cx--list cx--large-list" style={{ minHeight: "200px" }}>
                                    <img className="cx--primary-color" src={checkSvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Hedge shield to protect your valuable earnings</h6>
                                        <p>
                                            Built with merchants in mind, our infrastructure protects merchants from the volatility of digital assets.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="cx--list-wrapper">
                                <li className="cx--list cx--large-list" style={{ minHeight: "200px" }}>
                                    <img className="cx--primary-color" src={checkSvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Full stack accounting system</h6>
                                        <p>
                                            Our infrastructure includes an advanced full stack accounting system to
                                            aggregate and organize your sales, all in one smooth and easy-to-use
                                            merchant interface so you can keep track of your business at any point
                                            in time.
                                        </p>
                                    </div>
                                </li>
                                <li className="cx--list cx--large-list" style={{ minHeight: "200px" }}>
                                    <img className="cx--primary-color" src={checkSvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Comprehensive KYC process to uphold our
                                            commitment to safety </h6>
                                        <p>
                                            For any payment exceeding $800 USD, Cryllex requires a comprehensive KYC
                                            system that screens and monitors illegal users and funds, ensuring utmost
                                            compliance with all relevant AML policies.
                                        </p>
                                    </div>
                                </li>
                                <li className="cx--list cx--large-list" style={{ minHeight: "200px" }}>
                                    <img className="cx--primary-color" src={checkSvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Customer support tailored to your specific needs</h6>
                                        <p>
                                            Built with clients in mind, Cryllex offers customer support that is designed to
                                            provide you with solutions that actually solve the specific needs of your
                                            business.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="cx--section">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 mt-5">
                        <h2 className="cx--section-title cx--section-dark-title cx--text-transform-initial">
                            <span>We are</span> here to <span>Help</span>
                        </h2>
                        <p className="cx--card-body text-muted">
                            Have questions? Submit a support request and our team
                            will assist you in navigating your potential partnership
                            with Cryllex.
                        </p>
                        <div className="cx--btn-inline-group mt-3">
                            {/* <button
                                className="cx--btn cx--btn-primary cx--text-transform-initial ms-0"
                                type="button"
                                onClick={() => navigate("/contact-us")}
                            >
                                Get Started
                            </button> */}
                            <a
                                href='mailto: sales@cryllex.com'
                                className="cx--btn cx--btn-primary text-decoration-none text-white me-2"
                                type="button"
                            >
                                Get Started
                            </a>
                            <button
                                className="cx--btn cx--btn-primary-outline cx--text-transform-initial m-0"
                                type="button"
                                onClick={() => navigate("/contact-us")}
                            >
                                Contact Support
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center d-lg-block d-none">
                        <img className="cx--aside-image" src={ContactLaptopSvg} alt="contact-us" />
                    </div>
                </div>
            </div>
        </section>

    </React.Fragment>
}
