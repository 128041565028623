import React from 'react';
import { useNavigate } from 'react-router';
import NoData from '../../Components/NoData/NoData';

export default function Blog() {

  const navigate = useNavigate();

  return (
    <section className="cx--section mt-5">
      <div className="container">
        <div className='row'>
          <div className="cx--contact-info-wrapper">
            <strong className="cx--section-title-sm">Blog</strong>
          </div>
          <div className='mt-5'>
            <NoData />
          </div>
        </div>
      </div>
    </section >
  )
}
