import React from 'react'
import { fielsLengths } from '../../../Constants/MainKeys';

export default function PublicDetailsForCustomers(props) {

  const { values, isInvalidSubmit, onChange, onNumberChange } = props;

  return (
    <div className="row">
      <div className='col-12 mb-3'>
        <h3>Public Details for Customers</h3>
      </div>
      <div className="col-12">
        <div className='row'>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="statementDescriptor" className='mb-1 cursor-pointer'>Statement Descriptor *</label>
            <input
              id="statementDescriptor"
              type="text"
              name="statementDescriptor"
              value={values.statementDescriptor}
              className={`${(isInvalidSubmit && !values.statementDescriptor.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "statementDescriptor", fielsLengths.length_120)}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="shortenDescriptor" className='mb-1 cursor-pointer'>Shortened Descriptor *</label>
            <input
              id="shortenDescriptor"
              type="text"
              name="shortenedDescriptor"
              value={values.shortenDescriptor}
              className={`${(isInvalidSubmit && values.shortenDescriptor && !values.shortenDescriptor.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "shortenDescriptor", fielsLengths.length_120)}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="supportNumber" className='mb-1 cursor-pointer'>Customer Support Number</label>
            <input
              id="supportNumber"
              type="number"
              name="supportNumber"
              value={values.supportNumber}
              onChange={event => onNumberChange(event, "supportNumber")}
            />
          </div>

          <div className="col-lg-6 col-sm-12 mb-4">
            <label htmlFor="supportAddress" className='mb-1 cursor-pointer'>Customer Support Address *</label>
            <input
              id="supportAddress"
              type="text"
              name="supportAddress"
              value={values.supportAddress}
              className={`${(isInvalidSubmit && !values.supportAddress.trim().length) ? "cx--danger-border" : ""}`}
              onChange={event => onChange(event, "supportAddress", fielsLengths.length_120)}
            />
          </div>



        </div>

      </div>
    </div>
  )
}
