import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

export default function CookiesPolicy() {

  const navigate = useNavigate();

  const scrollToCurrentPosition = (activeSection) => {
    const currentSection = document.getElementById(activeSection);
    currentSection && window.scrollTo({
      top: currentSection?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
      behavior: "smooth"
    });
  }


  return (
    <section className="cx--section mt-5">
      <div className="container">
        <div className='row'>
          <div className="cx--contact-info-wrapper">
            <strong className="cx--section-title-sm">Cookie Policy</strong>
            <small className="d-block cx--contact-info-text mt-3">
              <span className='cursor-pointer text-underline' onClick={() => navigate("/")}>Home</span> / Cookie Policy</small>
            <small className="d-block cx--contact-info-text">Cryllex Inc.</small>
            <small className="d-block cx--contact-info-text">Last Updated: December 23rd, 2022 (example)</small>
          </div>

          <div className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">TABLE OF CONTENTS</strong>
            <ol className='mt-3'>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("overview")}><Link to="#">OVERVIEW</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("whatAreCookies")}><Link to="#">WHAT ARE COOKIES</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("cookiesWeUse")}><Link to="#">COOKIES WE USE</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("webBeacins")}><Link to="#">WEB BEACONS</Link></li>
              <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("howToManageCookies")}><Link to="#">HOW TO MANAGE COOKIES</Link></li>
            </ol>
          </div>

          <div id="overview" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">OVERVIEW</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                The Cookie Policy describes the different types of cookies that may be applied by Cryllex Global Inc.
                on its website (“Site”). We reserve the right to modify this Cookie Policy at any time in order to reflect,
                for example, changes to the cookies we use or for other operational, legal or regulatory reasons
              </span>
              <span className='d-block my-3'>
                If you have any questions regarding this Cookie Policy, please contact us at info@cryllex.com.
              </span>
            </div>
          </div>

          <div id="whatAreCookies" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">WHAT ARE COOKIES</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                Cookies are a standard feature of websites that allow us to store small amounts of data on your
                computer about your visit to a website. Cookies help the website owner learn which areas of the
                website are useful and which areas need improvement. Cookies also improve your experience by, for
                example, remembering your preferences, such as your language preference or login information.
              </span>
              <span className='d-block my-3'>
                Some cookies are required for technical reasons in order for a website to operate, also known as
                “Strictly Necessary” cookies. Some cookies allow a website to count visits and traffic sources so the
                site owner can measure and improve the performance of the site, also known as “Analytics” cookies.
                Some cookies enable a website owner to serve targeted advertisements, also known as “Targeting”
                cookies.
              </span>
            </div>
          </div>

          <div id="cookiesWeUse" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">COOKIES WE USE</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                Our Site uses both first party cookies (which are determined by us) and third party cookies (which are
                determined by a server located outside the domain of our Site) for several reasons. The specific types
                of first and third party cookies served through our Site and the purposes they perform are described
                in the tables below.
              </span>
              <span className='d-block mt-3'>
                Strictly Necessary Cookies. These are cookies which make our Site function when you take certain
                actions, whether you are logging in, managing email preferences, or filling out forms. Since they are
                essential for our Site to function, we cannot turn them off. You can set your browser to block or alert
                you about these cookies, but some parts of the Site will then not work. These cookies do not store
                personal data.
              </span>
            </div>

            <div className='table-responsive table-bordered mt-2'>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className='text-center'>Cookie Name</th>
                    <th scope="col" className='text-center'>Purpose</th>
                    <th scope="col" className='text-center'>First vs. Third Party</th>
                    <th scope="col" className='text-center'>Expiration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border'>
                    <td style={{ minWidth: "200px" }}>Cloudflare(_cfduid)</td>
                    <td style={{ minWidth: "200px" }}>
                      Cloudflare provides
                      content-delivery-network services,
                      DDos mitigation, Internet security,
                      and distributed
                      domain-name-server services. This
                      cookie collects and anonymizes end
                      user IP addresses using a one-way
                      hash of certain values so they
                      cannot be personally identified.
                    </td>
                    <td style={{ minWidth: "200px" }}>Third Party</td>
                    <td style={{ minWidth: "200px" }}>Session</td>
                  </tr>

                  <tr className='border'>
                    <td style={{ minWidth: "200px" }}>Connect_Sid</td>
                    <td style={{ minWidth: "200px" }}>This cookie is used to identify or create a valid user session.</td>
                    <td style={{ minWidth: "200px" }}>First Party</td>
                    <td style={{ minWidth: "200px" }}>Session</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cx--contact-info-text mt-2">
              <span>
                Analytics Cookies. These cookies allow us to count visits and traffic sources to our Site so we can
                measure and improve the performance of our Site. The information these cookies collect is
                aggregated and anonymous. If you do not allow these cookies, we will not be able to serve you the
                most relevant content and give you the best experience.'
              </span>
            </div>
            <div className='table-responsive table-bordered mt-2'>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className='text-center'>Cookie Name</th>
                    <th scope="col" className='text-center'>Purpose</th>
                    <th scope="col" className='text-center'>First vs. Third Party</th>
                    <th scope="col" className='text-center'>Expiration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border'>
                    <td style={{ minWidth: "200px" }}>Google Analytics</td>
                    <td style={{ minWidth: "200px" }}>
                      These cookies are used to calculate
                      visitor, session and campaign data
                      for the Sites' analytics reports
                      through aggregated, anonymous
                      user data.
                    </td>
                    <td style={{ minWidth: "200px" }}>Third Party</td>
                    <td style={{ minWidth: "200px" }}>6 months</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cx--contact-info-text  mt-2">
              <span>
                Targeting Cookies. These cookies may be set through our Site by our marketing partners. They may be
                used by us or our marketing partners to understand which parts of our Sites are of interest to you
                and/or show you relevant advertising on our Site or on other Sites. They do not store personal data,
                but are based on uniquely identifying your browser and internet device. If you do not allow these
                cookies, you will experience less targeted advertising.
              </span>
            </div>
            <div className='table-responsive table-bordered mt-2'>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className='text-center'>Cookie Name</th>
                    <th scope="col" className='text-center'>Purpose</th>
                    <th scope="col" className='text-center'>First vs. Third Party</th>
                    <th scope="col" className='text-center'>Expiration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border'>
                    <td style={{ minWidth: "200px" }}>LinkedIn Advertising</td>
                    <td style={{ minWidth: "200px" }}>
                      These cookies are used to provide
                      insight into user behavior within
                      LinkedIn that is responsive to our
                      marketing efforts, in order to deliver
                      marketing content to you such as
                      emails, white papers, webinars, etc.
                    </td>
                    <td style={{ minWidth: "200px" }}>Third Party</td>
                    <td style={{ minWidth: "200px" }}>6 months</td>
                  </tr>
                  <tr className='border'>
                    <td style={{ minWidth: "200px" }}>Google AdWords</td>
                    <td style={{ minWidth: "200px" }}>
                      These cookies are used to provide
                      insight into behavioral advertising
                      and analytics on the Google
                      AdWorks platform to enable us to
                      serve you with product-based
                      advertisements on external
                      websites.
                    </td>
                    <td style={{ minWidth: "200px" }}>Third Party</td>
                    <td style={{ minWidth: "200px" }}>540 days</td>
                  </tr>
                  <tr className='border'>
                    <td style={{ minWidth: "200px" }}>Facebook Advertising</td>
                    <td style={{ minWidth: "200px" }}>
                      These cookies allow us to confirm
                      when you have completed a form or
                      action related to Facebook. Cryllex
                      Global may send you related
                      communications via email or
                      sponsored newsfeed updates.
                    </td>
                    <td style={{ minWidth: "200px" }}>Third Party</td>
                    <td style={{ minWidth: "200px" }}>6 months</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div id="webBeacins" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">WEB BEACONS</strong>
            <div className="cx--contact-info-text mt-2">
              Cookies are not the only way to recognize or track visitors to a website. We may use other, similar
              technologies from time to time, like web beacons (sometimes called “tracking pixels” or “clear gifs”).
              These are tiny graphics files that contain a unique identifier that enable us to recognize when
              someone has visited our Site or opened an email that we sent. This allows us, for example, to monitor
              the traffic patterns of users from one page within our site to another, to deliver or communicate with
              cookies, to understand whether a user came to our Site from an online advertisement displayed on a
              third-party website, to improve site performance, and to measure the success of email marketing
              campaigns. In many instances, these technologies are reliant on cookies to function properly, and so
              declining cookies will impair their functioning.
            </div>
          </div>

          <div id="howToManageCookies" className="cx--contact-info-wrapper mt-4">
            <strong className="cx--section-title-lg">HOW TO MANAGE COOKIES</strong>
            <div className="cx--contact-info-text mt-2">
              <span>
                You can choose whether to accept our cookies (except Strictly Necessary Cookies) by clicking on the
                appropriate opt-out links in our Cookie Preference Center, which can be found in our Site's footer and
                cookie banner. You also have the right to refuse or accept cookies at any time by activating settings on
                your browser. You may wish to refer to
                &nbsp;<a className='text-nowrap' href='http://www.allaboutcookies.org/manage-cookies/index.html' target="_blank">
                  http://www.allaboutcookies.org/manage-cookies/index.html</a>&nbsp;
                for information on commonly used browsers.
                <br />
                Note that blocking some types of cookies may impact your experience on our Site and the services
                we are able to offer you.
              </span>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}
