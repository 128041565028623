import React from "react";
import {
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import AboutUs from "../../Pages/AboutUs/AboutUs";
import Blog from "../../Pages/Blog/Blog";
import Business from "../../Pages/Business/Business";
import ComingSoon from "../../Pages/ComingSoon/ComingSoon";
import ContactUs from "../../Pages/ContactUs/ContactUs";
import DeveloperDocuments from "../../Pages/DeveloperDocuments/DeveloperDocuments";
import FAQ from "../../Pages/FAQ/FAQ";
import ForBusiness from "../../Pages/ForBusiness/ForBusiness";
import GetStarted from "../../Pages/GetStarted/GetStarted";
import Home from "../../Pages/Home/Home";
import IntegrationGuide from "../../Pages/IntegrationGuide/IntegrationGuide";
import Partners from "../../Pages/Partners/Partners";
import CookiesPolicy from "../../Pages/Resources/CookiesPolicy";
import PrivacyPolicy from "../../Pages/Resources/PrivacyPolicy";
import ShopWithCrypto from "../../Pages/ShopWithCrypto/ShopWithCrypto";
import SignOut from "../../Pages/SignOut/SignOut";
import SupportedCoins from "../../Pages/SupportedCoins/SupportedCoins";
import SupportedWallets from "../../Pages/SupportedWallets/SupportedWallets";
import WhyUs from "../../Pages/WhyUs/WhyUs";
import Help from "../../Pages/Help/Help";
import Solutions from "../../Pages/Solutions/Solutions";

const AuthUserRouting = () => {
  return <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/business" element={<Business />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/solutions" element={<Solutions />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/sign-out" element={<SignOut />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/cookies-policy" element={<CookiesPolicy />} />

    <Route path="/for-businesses" element={<ForBusiness />} />
    <Route path="/coming-soon" element={<ComingSoon />} />
    <Route path="/shop-with-crypto" element={<ShopWithCrypto />} />
    <Route path="/supported-coins" element={<SupportedCoins />} />
    <Route path="/supported-wallets" element={<SupportedWallets />} />
    <Route path="/why-us" element={<WhyUs />} />
    <Route path="/get-started" element={<GetStarted />} />
    <Route path="/partners" element={<Partners />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/integration-guide" element={<IntegrationGuide />} />
    <Route path="/developer-documents" element={<DeveloperDocuments />} />
    <Route path="/faq" element={<FAQ />} />
    <Route path="/help" element={<Help />} />

    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
}

export default AuthUserRouting;
