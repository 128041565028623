import React from "react";
import UnAuthUsersRouting from "../../Routing/UnAuthUserRouting/UnAuthUserRouting";
import Footer from "../LayoutSections/Footer/Footer";
import UnauthHeader from "../LayoutSections/Header/UnauthHeader";
import { useLocation } from "react-router";

const UnAuthUsersLayout = () => {
  const { pathname } = useLocation()

  return (
    <React.Fragment>
      <UnauthHeader />
      <UnAuthUsersRouting />
      {
        pathname !== "/sign-in" && pathname !== "/sign-up" ?
          <Footer />
          : null
      }
    </React.Fragment>
  );
}

export default UnAuthUsersLayout;

