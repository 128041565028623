export const ADD_PAGE_SPINNER = "ADD_PAGE_SPINNER";
export const REMOVE_PAGE_SPINNER = "REMOVE_PAGE_SPINNER";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const ADD_BUTTON_SPINNER = "ADD_BUTTON_SPINNER";
export const REMOVE_BUTTON_SPINNER = "REMOVE_BUTTON_SPINNER";
export const USER_REGISTRATION_REQUEST = "USER_REGISTRATION_REQUEST";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOG_OUT = "LOG_OUT";
export const REMOVE_LOGIN_ERROR_MESSAGE = "REMOVE_LOGIN_ERROR_MESSAGE";
export const SET_IS_SHOW_PAGE_CONTENT_SPINNER = "SET_IS_SHOW_PAGE_CONTENT_SPINNER";
export const SET_PAGE_CONTENT = "SET_PAGE_CONTENT";
export const SET_PAGE_CONTENT_ID = "SET_PAGE_CONTENT_ID";
export const GET_PAGE_CONTENT_BY_ID = "GET_PAGE_CONTENT_BY_ID";