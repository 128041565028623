import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';

export default function CookiesModal() {

  const navigate = useNavigate();
  const [isShowCookiesModal, setIsShowCookiesModal] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("acceptedCookies")) {
      setIsShowCookiesModal(true);
    } else {
      setIsShowCookiesModal(false);
    }
  }, [localStorage.getItem("acceptedCookies")]);

  const hideModal = () => {
    setIsShowCookiesModal(false);
    localStorage.setItem("acceptedCookies", true)
  }

  return (isShowCookiesModal && !window.location.pathname.includes("cookies-policy") && !window.location.pathname.includes("privacy-policy") ?
    <div className='cookie-modal'>
      <div className='cookie-modal-block p-4'>
        <p className='m-0'>This website uses cookies to enhance the user experience.</p>
        <div>
          <button className="cx--btn cx--btn-primary btn-small" onClick={hideModal}>Accept</button>
          <button className="cx--btn cx--btn-primary-outline" onClick={() => navigate("/cookies-policy")}>Learn more</button>
        </div>
      </div>
    </div>
    : null
  )
}
