import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import {
  addPageSpinner,
  removePageSpinner,
} from "./../../Store/Actions/spinner";
import Parser from "html-react-parser";
import { useSelector } from "react-redux";
import { Accordion } from 'react-bootstrap';
import { getPageContentById, setPageContentId } from "./../../Store/Actions/pageContent";
import { PuffLoader } from "react-spinners";
import { GridLoader } from "react-spinners";
import NoData from '../../Components/NoData/NoData';
import { ERROR_KEY } from "../../Constants/MainKeys";
import ApiService from './../../Services/ApiService';
import AlertService from './../../Services/AlertService';

const Help = () => {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null);
  const { pageContent, pageContentId, isShowPageContentSpinner } = useSelector((state) => state.pageContent);

  useEffect(() => {
    getPage();
  }, []);

  useEffect(() => {
    if (pageContentId) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      dispatch(getPageContentById(pageContentId));
    }
  }, [pageContentId]);

  const getPage = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getPageByName("help")
      .then((response) => {
        if (response && response.data) {
          const data = { ...response.data };
          if (data && data.contents && data.contents.length) {
            dispatch(setPageContentId(data.contents[0].id));
          }
          setPageData(response.data);
        }
        extractPageSpinner(spinnerId);
      })
      .catch((error) => getFail(error, spinnerId));
  };

  const setPageSpinner = useCallback((spinner) => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback((spinner) => {
    dispatch(removePageSpinner(spinner));
  }, []);


  const getFail = (error, spinnerId) => {
    error &&
      AlertService.alert(
        AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && extractPageSpinner(spinnerId);
  };

  return (
    <>
      <section className="cx--section mt-5">
        <div className="container">
          <div className="row position-relative">
            <div className="col-md-5 col-12">
              <div className="row">
                <div className="cx--contact-info-wrapper">
                  <strong className="cx--section-title-sm">Help</strong>
                </div>
                <div className="col-12">
                  <ul className="price-list list-unstyled my-4 text-small text-left cryllex-list-menu cryllex-accordion-left-menu with-icon">
                    {
                      pageData ?
                        <LeftMenu data={pageData} />
                        : null
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12 p-4">
              {
                isShowPageContentSpinner ?
                  <div className="card-spinner spinner-layer position-relative" style={{ height: "500px", top: 0, zIndex: "1" }}>
                    <div className="sweet-loading">
                      <GridLoader
                        size={10}
                        color={"#0533B3"} />
                    </div>
                  </div>
                  : pageContent ? (
                    <>
                      <h2 className="cryllex-block-title mb-4">
                        {pageContent.title}
                      </h2>
                      {Parser(pageContent.fullContent)}
                    </>
                  ) : <div className="d-flex align-items-center justify-content-center" style={{ height: "500px", top: 0, zIndex: "1" }}>
                    <NoData height={200} width={200} />
                  </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const LeftMenuContent = ({ contents }) => {

  const dispatch = useDispatch();
  const { pageContentId } = useSelector((state) => state.pageContent);

  return (<>
    {
      contents && contents.length ?
        contents.map((item) => {
          return <div key={item.id} className="accordion-item">
            <h2 className={`without-arrow accordion-header ${pageContentId === item.id ? "active-content" : ""}`}>
              <button
                className={`accordion-button collapsed capitalize`}
                onClick={() => {
                  dispatch(setPageContentId(item.id));
                }}
              >
                {item.title}
              </button>
            </h2>
          </div>
        })
        : null
    }
  </>
  )
}

const LeftMenuChildren = ({ children }) => {

  return (<>
    {
      children && children.length ?
        children.map(item => {
          return <Accordion.Item key={item.id} eventKey={item.id}>
            <Accordion.Header className="capitalize">{item.pageTitle}</Accordion.Header>
            <Accordion.Body className="pl-1 pt-0">
              <LeftMenuContent contents={item.contents} />
              <LeftMenuChildren children={item.children} />
            </Accordion.Body>
          </Accordion.Item>
        })
        : null
    }
  </>
  )
}

const LeftMenu = ({ data }) => {
  return (
    <Accordion defaultActiveKey={[]} alwaysOpen>
      <LeftMenuContent contents={data?.contents} />
      <LeftMenuChildren children={data?.children} />
    </Accordion>
  )
}

export default Help