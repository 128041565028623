import React, { useCallback, useEffect } from 'react';
import { useNavigate } from "react-router";
import cryptoCoinSvg from "./../../assets/images/crypto-coins.svg";
import whiteLogoPoorSvg from "./../../assets/logo/white-logo-poor.svg";
import checkPrimarySvg from "./../../assets/icons/check-primary.svg";
import phonePng from "./../../assets/images/phone.png";
import decor4x4Svg from "./../../assets/images/decors/decor-4x4.svg";
import paymentSvg from "./../../assets/icons/w35/payment.svg";
import invoiceSvg from "./../../assets/icons/w35/invoice.svg";
import cryptoCurrencies from "./../../assets/images/crypto-currencies.svg";
import kycSvg from "./../../assets/icons/w35/kyc.svg";
import accountingSystemSvg from "./../../assets/icons/w35/accounting-system.svg";
import refoundSystemSvg from "./../../assets/icons/w35/refound-system.svg";
import transferSystemSvg from "./../../assets/icons/w35/transfer-system.svg";
import decor4x6Svg from "./../../assets/images/decors/decor-4x6.svg";
import laptopSvg from "./../../assets/images/laptop.svg";
import decor2x6Svg from "./../../assets/images/decors/decor-2x6.svg";
import conversionSvg from "./../../assets/icons/w35/conversion.svg";
import transferSvg from "./../../assets/icons/w35/transfer.svg";
import coverSvg from "./../../assets/images/decors/home-cover-decor.svg";
import Partners from './Components/Partners';
import ContactUsComponent from '../ContactUs/ContactUsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { addPageSpinner, removePageSpinner } from './../../Store/Actions/spinner';
import AlertService from '../../Services/AlertService';
import uuid from 'react-uuid';
import ContactLaptopSvg from "./../../assets/images/contact-laptop.svg"
import MainService from '../../Services/MainService';

const Home = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, token } = useSelector(state => state.user);

    useEffect(() => {
        const spinnerId = uuid();
        setPageSpinner(spinnerId);

        setTimeout(() => {
            extractPageSpinner(spinnerId)
        }, 1000);
    }, []);

    const setPageSpinner = useCallback(spinner => {
        dispatch(addPageSpinner(spinner));
    }, []);

    const extractPageSpinner = useCallback(spinner => {
        dispatch(removePageSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
        spinnerId && extractPageSpinner(spinnerId);
    };

    return (
        <React.Fragment>
            <section className="cx--cover-container">
                <img className="cx--cover-decor" src={coverSvg} alt="cover-decoration" />
                <div className="container">
                    <div className="cx--cover-body-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <h1 className="cx--cover-title">
                                    <span className="cx--primary-color">Blockchain</span> and
                                    <br />
                                    <span className="cx--primary-color">Digital Payment</span>
                                    <br />
                                    Infrastructure
                                    <br />
                                    for Businesses
                                </h1>
                                <p className="cx--cover-text mt-3">
                                    Cryllex specializes in cutting-edge solutions designed to
                                    optimize digital payment processes for businesses by
                                    offering faster, cheaper, and smarter payments.
                                </p>
                            </div>
                            <div className="col-lg-5">
                                <div className="cx--cover-image">
                                    <img src={cryptoCoinSvg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cx--primary-background">
                    <div className="container">
                        <div className="cx--cover-bottom-container cx--sm-section mt-5">
                            <div className="cx--cover-bottom-title-wrapper">
                                <img src={whiteLogoPoorSvg} alt="" />
                                <div className="cx--cover-bottom-title">
                                    <h3>ACCELERATE YOUR BUSINESS</h3>
                                    <p className='cx--text-mute'>Increase your payment offerings and service a global consumer base.</p>
                                </div>
                            </div>
                            <div className="cx--btn-inline-group">
                                <button
                                    className="cx--btn cx--btn-white"
                                    type="button"
                                    onClick={() => navigate("/solutions")}
                                >
                                    Our Solutions
                                </button>
                                {/* <button
                                    className="cx--btn cx--btn-white-outline"
                                    type="button"
                                    onClick={() => navigate("/contact-us")}
                                >
                                    Contact Sales
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx--section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="cx--section-title cx--section-dark-title">
                                <span>Blockchain</span> Powered Payments
                            </h2>
                            <h5 className="cx--section-subtitle cx--section-dark-subtitle">See the advantages Cryllex has to offer.</h5>
                        </div>
                        <div className="col-md-7">
                            <ul className="cx--list-wrapper mt-5">
                                <li className="cx--list cx--large-list">
                                    <img className="cx--primary-color" src={checkPrimarySvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Seamless Integration</h6>
                                        <p>
                                            A swift integration is all that is required to tap into our infrastructure and start accepting crypto.
                                        </p>
                                    </div>
                                </li>
                                <li className="cx--list cx--large-list">
                                    <img className="cx--primary-color" src={checkPrimarySvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Extremely Competitive Transaction Fees</h6>
                                        <p>
                                            We understand that eCommerce businesses face particularly tight profit margins, strained
                                            further by fees charged by traditional payment networks. Created with merchants in mind, we
                                            charge only an iota of what you’re used to.

                                        </p>
                                    </div>
                                </li>
                                <li className="cx--list cx--large-list">
                                    <img className="cx--primary-color" src={checkPrimarySvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Zero Price Volatility</h6>
                                        <p>
                                            Cryptocurrencies are notoriously volatile. Cryllex was built with the intention of
                                            protecting merchants from just that. You earn every dollar of every sale, in fiat,
                                            regardless of where cryptocurrency prices stand in the market.
                                        </p>
                                    </div>
                                </li>
                                <li className="cx--list cx--large-list">
                                    <img className="cx--primary-color" src={checkPrimarySvg} alt="check" />
                                    <div className="cx--list-item">
                                        <h6>Fast & Secure</h6>
                                        <p>
                                            Payments are processed instantaneously with end-of-day settlements, secured
                                            with blockchain technology.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5">
                            <div className="cx--section-image-wrapper">
                                <img src={phonePng} alt="/" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx--section cx--primary-background position-relative">
                <img className="cx--section-decoration cx--section-decoration-left" src={decor4x4Svg} alt="decoration" />
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h2 className="cx--section-title cx--section-light-title">
                                Get Started Now
                            </h2>
                            <h5 className="cx--section-subtitle cx--section-light-subtitle">
                                Explore our solutions to learn more, or create an account
                                to enhance your digital payment versatility.
                            </h5>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="cx--btn-inline-group d-flex h-100 align-items-center justify-content-lg-end">
                                {/* <button
                                    className="cx--btn cx--btn-white"
                                    type="button"
                                    onClick={() => navigate("/solutions")}
                                >
                                    Solutions
                                </button> */}
                                {
                                    user ?
                                        <a
                                            className="cx--btn cx--btn-white text-decoration-none"
                                            href={`${user ? "#" : `https://dashboard.cryllex.${MainService.getUrlExtension()}/en/dashboard`}`}
                                        >
                                            Dashboard
                                        </a>
                                        : <button
                                            className="cx--btn cx--btn-white"
                                            type="button"
                                            style={{ textTransform: "inherit" }}
                                            onClick={() => {
                                                navigate("/create-account")
                                            }}
                                        >
                                            Become a Merchant
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx--section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="cx--section-image-wrapper">
                                <img src={cryptoCurrencies} alt="crypto-currencies" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cx--section-title-wrapper">
                                <h2 className="cx--section-title cx--section-dark-title cx--text-transform-initial">
                                    Access to a <br /><span>Trillion Dollar </span>Market
                                </h2>
                                <h5 className="cx--section-subtitle cx--section-dark-subtitle">We help you connect.</h5>
                            </div>
                            <p className="mt-md-5 mt-3">
                                The digital asset space is growing rapidly. As consumers, institutions and
                                governments continue to uncover the value of digital currencies, it will only become
                                more integrated with the global economy.
                                <br />
                                <br />
                                We built the bridge for you to begin your transition from web2.0 to web3.0. Cryllex
                                gives you access to an estimated 70 million users from 165+ wallets and over 500
                                cryptocurrencies.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='position-relative'>
                <div className="container mb-4">
                    <h2 className="cx--section-title cx--section-dark-title">
                        Key Market Statistics
                    </h2>
                </div>
                <div className="cx--sm-section cx--primary-background">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                <div className="cx--horizontal-list">
                                    <strong>$2T+</strong><span>Global Crypto Market Cap</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="cx--horizontal-list">
                                    <strong>300M</strong><span>Active crypto wallets worldwide
                                        <br />(as of 1/1/2022)</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="cx--horizontal-list">
                                    <strong>12.2%</strong><span>Projected CAGR 2021-2030</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="cx--horizontal-list">
                                    <strong>67%</strong><span>Crypto owners agree there is a lack of
                                        merchants that accept cryptocurrency
                                        as payment</span>
                                </div>
                            </div>
                        </div>
                        <img className="cx--section-decoration cx--section-decoration-bottom" src={decor2x6Svg} alt="decoration" />
                    </div>
                </div>
            </section>

            <section className="cx--section position-relative mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="cx--section-title">
                                <span>Our</span> Story
                            </h2>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-md-4 col-sm-6 my-2 position-relative">
                            <div className="cx--card cx--bordered-card-wrapper">
                                <div className="cx--card-title-wrapper">
                                    <h6 className="m-0 cx--card-title">Company</h6>
                                </div>
                                <p className="cx--card-body">Cryllex was founded by blockchain enthusiasts
                                    that want to normalize the use of cryptocurrencies
                                    in everyday transactions.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 my-2 position-relative">
                            <div className="cx--card cx--bordered-card-wrapper">
                                <div className="cx--card-title-wrapper">
                                    <h6 className="m-0 cx--card-title">Solution</h6>
                                </div>
                                <p className="cx--card-body">
                                    Even the most notable cryptocurrencies have volatility risks.
                                    Not only that, transferring crypto from one wallet to another takes time, leaving room for value fluctuations.
                                    With Cryllex, merchants have all the exposure, but none of the risk.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 my-2 position-relative">
                            <div className="cx--card cx--bordered-card-wrapper">
                                <div className="cx--card-title-wrapper">
                                    <h6 className="m-0 cx--card-title">Vision</h6>
                                </div>
                                <p className="cx--card-body">The world is changing. We see a bright future
                                    ahead for crypto and digital assets, and believe
                                    that it will become increasingly intertwined with
                                    the global economy as more and more merchants
                                    learn about the value and opportunities that
                                    blockchain technology creates.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx--section cx--gray-background position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="cx--section-title-wrapper">
                                <h2 className="cx--section-title cx--section-dark-title">
                                    A <span>Smoother</span> Checkout <br /> Experience
                                </h2>
                                <h5 className="cx--section-subtitle cx--section-dark-subtitle">Checkout in seconds.</h5>
                            </div>
                            <p className="mt-md-5 mt-3">
                                Traditional payment methods can be long and frustrating, requiring you to input a card
                                number, security code and more. With Cryllex, all you need to do is select your desired
                                cryptocurrency and scan the QR code generated on the screen.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="cx--section-image-wrapper">
                                <img src={laptopSvg} alt="laptop" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Partners /> */}
            <section className="cx--section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center d-lg-block d-none">
                            <img className="cx--aside-image" src={ContactLaptopSvg} alt="contact-us" />
                        </div>
                        <div className="col-lg-6 mt-5">
                            <h2 className="cx--section-title cx--section-dark-title cx--text-transform-initial">
                                <span>Talk</span> to Our Team
                            </h2>
                            <p className="cx--card-body">
                                Learn how Cryllex can enhance your business and expand your
                                digital payment capabilities to realize your earnings potential.
                            </p>
                            {/* <button
                                className="cx--btn cx--btn-primary mt-3"
                                type="submit"
                                onClick={() => navigate("/contact-us")}
                            >
                                Let’s Connect
                            </button> */}
                            <a
                                href='mailto: sales@cryllex.com'
                                className="cx--btn cx--btn-primary  text-decoration-none text-white"
                                type="button"
                            >
                                Let’s Connect
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Home
