import upArrow from "../../assets/icons/up-arrow.svg";
import { useEffect } from "react";

const ScrollTopView = (props) => {
  const { onScroll, onVisbile, visible } = props;

  useEffect(() => {
    if (typeof window === 'object') {
      window.addEventListener('scroll', onVisbile);
    }
    return () => {
      window.removeEventListener('scroll', onVisbile);
    }
  }, []);

  return (
    <>
      {
        visible ?
          <div
            className={`scroll-top`}
            onClick={onScroll}
          >
            <img src={upArrow} alt="" />
          </div>
          : null
      }
    </>
  )
}

export default ScrollTopView